/* Written by Nishant Jain */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Lottie from 'lottie-web'; // Import the Lottie library
import { connect } from 'react-redux';
import people from '../../assets/people.png';
import homePageAnimation from '../../assets/homePageAnimation.json';
import './header.css';
import NewForm from './NewForm';

const Header = () => {
  const currentURL = window.location.href;

  // Define the URL where you don't want the component to be displayed
  const excludedURL = 'localhost:3000';
  const [shouldDisplay, setShouldDisplay] = useState(true);

  if (currentURL === excludedURL) {
    // Set shouldDisplay to false if the URLs match
    setShouldDisplay(false);
  }

  // Use state to track whether the component should be displayed
  // Function to handle Lottie animation loading
  React.useEffect(() => {
    const animationContainer = document.getElementById('lottie-animation-container');
    if (animationContainer) {
      Lottie.loadAnimation({
        container: animationContainer,
        renderer: 'svg', // You can choose the renderer (svg/canvas/html)
        loop: true,
        autoplay: true,
        animationData: homePageAnimation, // Pass the animation data here
      });
    }
  }, []);

  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">Make informed investment decisions</h1>
        <p>Fintuition is a stock valuation platform that makes value investing easier.</p>

        <div className="gpt3__header-content__input">
          {shouldDisplay && (
          <div>
            <NewForm />
          </div>
          )}
        </div>
      </div>

      {/* Add a container for the Lottie animation */}
      <div className="gpt3__header-image">
        <div id="lottie-animation-container" style={{ width: '400px', height: '400px' }} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tickerData: state.tickerData,
});

export default connect(mapStateToProps)(Header);
