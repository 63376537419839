/* Written by Nishant Jain */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { connect } from 'react-redux';

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column; /* Updated to stack elements vertically */
  padding-left: 20px;
  padding-right: 10px;
  padding-bottom: 10px;
  position: relative;
  border-radius: 4px;
  margin-bottom: 20px; /* Added margin for spacing */
`;

const SuggestionsList = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 150px; /* Limit the height for scroll if there are many suggestions */
  overflow-y: auto; /* Enable vertical scroll if needed */
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  background-color: #fff; /* White background color */
  z-index: 100;
`;

const SuggestionItem = styled('li')`
  padding: 12px;
  border-bottom: 1px solid #eee; /* Light grey border between items */
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:last-child {
    border-bottom: none; /* Remove border for the last item */
  }

  &:hover {
    background-color: #f0f0f0; /* Light grey background color on hover */
  }
`;
const SearchInput = styled('input')`
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: grey;
  margin-bottom: 10px;
  &:focus {
    outline: none;
  }
`;

const SubmitButton = styled('button')`
  display: none; /* Hide the submit button */
`;

const NewForm = () => {
  const [ticker, setTicker] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  const fetchSuggestions = async (query) => {
    try {
      if (query.trim() === '') {
        setSuggestions([]); // Set suggestions to empty array if query is empty or contains only whitespace
        return;
      }
      const response = await fetch(`http://127.0.0.1:8000/api/stocks/suggestions/?query=${query}`);
      const data = await response.json();
      setSuggestions(data.suggestions);
    } catch (error) {
      // eslint-disable-next-line
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    navigate(`/dcf/${ticker.toUpperCase()}/`);
  };

  const handleSuggestionClick = (selectedTicker) => {
    setTicker(selectedTicker);
    navigate(`/dcf/${selectedTicker.toUpperCase()}/`);
    setSuggestions([]);
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <label>
        <SearchInput
          type="text"
          placeholder="Enter a valid ticker.."
          value={ticker}
          onChange={(event) => {
            setTicker(event.target.value);
            fetchSuggestions(event.target.value);
          }}
        />
      </label>
      {suggestions.length > 0 && (
        <SuggestionsList>
          {suggestions.map((suggestion) => (
            <SuggestionItem
              key={suggestion.symbol}
              onClick={() => handleSuggestionClick(suggestion.symbol)}
            >
              {suggestion.symbol && suggestion.company_name
                ? `${suggestion.symbol} - ${suggestion.company_name}`
                : 'Invalid suggestion'}
            </SuggestionItem>
          ))}
        </SuggestionsList>
      )}
      <SubmitButton type="submit">Get Fair Value</SubmitButton>
    </StyledForm>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setTickerData: (data) => dispatch({ type: 'SET_TICKER_DATA', payload: data }),
});

export default connect(null, mapDispatchToProps)(NewForm);
