import { configureStore, createAction } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import webStorage from 'redux-persist/lib/storage';

// Your initial state
const initialState = {
  tickerData: null,
  whichCase: 'Base Case',
  isAuthenticated: false,
  user: null,
  tickersEntered: [], // New state for tickers entered by the user
  isDropdownOpen: false, // Add isDropdownOpen to the initial state
};

// Your reducer
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TICKER_DATA':
      return {
        ...state,
        tickerData: action.payload,
      };
    case 'SET_CASE':
      return {
        ...state,
        whichCase: action.payload,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_AUTHENTICATED':
      if (!action.payload) {
        // Reset tickersEntered when the user logs out
        return {
          ...initialState,
          isAuthenticated: false,
        };
      }
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case 'ADD_TICKER': // Action to add a new ticker
      return {
        ...state,
        tickersEntered: [...state.tickersEntered, action.payload],
      };
    case 'SET_DROPDOWN_OPEN':
      return {
        ...state,
        isDropdownOpen: action.payload,
      };
    default:
      return state;
  }
};

// Create the persisted reducer
const persistConfig = {
  key: 'root',
  storage: webStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
const store = configureStore({
  reducer: persistedReducer,
});

// Define the action creators using createAction
export const setTickerData = createAction('SET_TICKER_DATA');
export const setCase = createAction('SET_CASE');
export const setUser = createAction('SET_USER');
export const setAuthenticated = createAction('SET_AUTHENTICATED');
export const addTicker = createAction('ADD_TICKER'); // Define the action creator to add a ticker
export const setIsDropdownOpen = createAction('SET_DROPDOWN_OPEN'); // Define action creator for dropdown

export default store;

// Create the persistor
export const persistor = persistStore(store);
