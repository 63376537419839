/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* Written by Nishant Jain */
/* eslint-disable react/self-closing-comp */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-this-in-sfc */
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { TfiTarget } from 'react-icons/tfi';
import { CountUp } from 'countup.js';
import { RiMenu3Line, RiCloseLine, RiArrowUpDownLine } from 'react-icons/ri';
import { FaCheck, FaTimes, FaEnvelope, FaLock, FaCalculator } from 'react-icons/fa'; // Import icons
import { faPencilAlt, faQuoteLeft, faCalculator, faSearch, faPercent, faDollarSign, faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Assuming you're using FontAwesome icons
import Lottie from 'lottie-web';
import { Provider, useSelector, useDispatch } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import { BrowserRouter as Router, Routes, Route, Switch, Link, useParams, useHistory, useNavigate, useLocation } from 'react-router-dom'; // Import BrowserRouter, Routes, and Route
import { Footer, Possibility, Features, WhatGPT3, Header } from './containers';
import { CTA, Brand } from './components';
import store, { persistor, setTickerData, setCase, setUser, setAuthenticated, addTicker, setIsDropdownOpen } from './store'; // Import the persistor
import FintuitionLogo from './assets/fintuitionLogo.svg';
import LoadingAnimation from './assets/loading.json';
import './App.css';
import NewForm from './containers/header/NewForm';
import { compose } from 'redux';
import styled from 'styled-components';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { IoMdMail, IoMdPerson } from 'react-icons/io';
import { MdCamera } from 'react-icons/md'; // Import the camera icon
import StatsAnimation from './assets/stats.json'; // Import your Lottie animation file
import LoadingGear from './assets/LoadingGear.json'; // Import your Lottie animation file
import anime from 'animejs';
import { fontFamily } from '@mui/system';

const SignUpButton = () => {
  const [showCard, setShowCard] = useState(false);
  const overlayRef = useRef(null);
  const [showError, setShowError] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false); // New state for registration success
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const isAuthenticated = useSelector((state) => state.isAuthenticated); // Get the isAuthenticated value from the store
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(''); // Add error message state

  const handleSignUpWithDelay = async () => {
    await handleSignUp(); // First, handle the sign-up logic
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  useEffect(() => {
    if (registrationSuccess) {
      const delay = 2500; // 5 seconds
      const timeoutId = setTimeout(() => {
        navigate('/profile');
        dispatch(setAuthenticated(true)); // Dispatch the action to set isAuthenticated to true
      }, delay);

      return () => clearTimeout(timeoutId);
    }
  }, [registrationSuccess, navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSignUp = async () => {
    const registrationData = {
      email,
      password,
    };

    try {
      const response = await fetch('https://www.fintuition.org/api/register/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData),
      });
      const responseData = await response.json();
      if (response.ok) {
        // User successfully registered
        // You can perform additional actions here, such as redirecting to another page
        setShowError(false);
        setErrorMessage('');
        setRegistrationSuccess(true); // Set the registration success state to true
        dispatch(setUser(responseData.email)); // Assuming that the user information is stored in responseData.user
      } else {
        // Handle error response
        const data = await response.json();
        if (data.email) {
          if (data.email[0] === 'custom user with this email already exists.') {
            setErrorMessage('Please enter an unused email.');
            setShowError(true); // Show the error box
          } else if (data.email[0] === 'Enter a valid email address.') {
            setErrorMessage('Please enter a valid email address.');
            setShowError(true); // Show the error box
          }
        } else {
          setErrorMessage(data.email || data.password || 'An error occurred during registration.');
          setShowError(true); // Show the error box
        }
      }
    } catch (error) {
      setErrorMessage('Error with registration.');
      setShowError(true); // Show the error box
      console.log('ERROR MESSAGE IS: ', errorMessage);
    }
  };
  useEffect(() => {
    const overlayElement = overlayRef.current;

    const flipInAnimation = anime.timeline({
      targets: overlayElement,
      autoplay: false,
    });

    flipInAnimation
      .add({
        duration: 400,
        opacity: [0, 1],
        easing: 'easeInOutQuad',
      })
      .add({
        duration: 800,
        rotateY: [180, 0],
        easing: 'easeInOutQuad',
        complete: () => {
          // Animation is complete, show the content
          setShowCard(true);
        },
      }, '-=500') // Start the rotation animation 500ms before the opacity animation ends
      .add({
        begin: () => {
          overlayElement.style.display = 'flex';
        },
        duration: 1,
      });

    if (showCard) {
      flipInAnimation.play();
    }
  }, [showCard]);

  const handleClick = () => {
    setShowCard(!showCard);
  };

  const handleCardClose = () => {
    setShowCard(false);
  };

  const cardStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      display: showCard ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
    },
    cardContainer: {
      width: '20%',
      height: showError ? '470px' : '400px', // Set a fixed height for the card
      background: '#000',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      padding: '20px',
      textAlign: 'center',
      justifyContent: 'center',
      position: 'relative',
      transformStyle: 'preserve-3d',
      transition: 'transform 0.5s', // Add transition property
      transform: `rotateY(${showCard ? '0' : '180deg'})`, // Apply the rotation
    },
    content: {
      transform: `rotateY(${showCard ? '0' : '-180deg'})`, // Apply opposite rotation for the content
    },
    errorBox: {
      backgroundColor: 'black',
      border: '1px solid red',
      padding: '10px',
      top: '70px',
      position: 'relative',
      marginTop: '4 0px',
      textAlign: 'center',
      bottom: '-100px',
    },
    signUpButton: {
      width: '100px', // Set the width to make the button smaller
      height: '30px', // Set the height to make the button smaller
      backgroundColor: '#0800F0', // Use light blue as the background color
      border: 'none', // Remove the border
      borderRadius: '10px', // Add a slight border radius for a rounded appearance
      right: '13%',
      overflow: 'hidden',
      display: 'flex', // Use flex display
      justifyContent: 'center', // Center horizontally
      alignItems: 'center', // Center vertically
      fontSize: '18px', // Adjust font size
      fontWeight: 'bold', // Set font weight to bold
      fontFamily: 'Lato',
      position: 'absolute',
      top: '17.5px',
    },
    fintuitionText: {
      fontFamily: 'Lato',
      fontSize: '25.2px',
      background: 'linear-gradient(to right, #3498db, #2ecc71)',
      WebkitBackgroundClip: 'text',
      color: 'transparent',
      marginBottom: '50px',
      justifyContent: 'center',
      position: 'relative',
      left: '5px',
    },
    taglineText: {
      fontFamily: 'Lato',
      fontSize: '16px',
      justifyContent: 'center',
      color: '#7b8793',
      top: '-40px',
      position: 'relative',
    },
    checkmarkOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent black background
      display: registrationSuccess ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
    },
    checkmark: {
      fontSize: '10rem',
      color: 'green',
    },
    inputField: {
      width: '100%',
      padding: '10px',
      margin: '10px 0',
      fontSize: '14px',
      border: 'none',
      borderRadius: '5px',
      background: '#34495e',
      color: '#ecf0f1',
      bottom: '0px',
      position: 'relative',
    },
    inputFieldLabel: {
      color: '#ecf0f1',
      textAlign: 'left',
      marginBottom: '5px',
      display: 'block',
      bottom: '0px',
      fontFamily: 'Lato',
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      fontSize: '18px',
      color: '#ecf0f1',
      cursor: 'pointer',
    },
    signUpWithEmailButton: {
      padding: '10px',
      fontSize: '16px',
      marginTop: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#3498db',
      color: '#ecf0f1',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    orText: {
      margin: '10px 0',
      position: 'relative',
      color: '#7b8793',
      bottom: '-20px',
    },
    errorMessage: {
      color: 'red',
      fontFamily: 'Lato',
      fontSize: '14px',
      marginBottom: '100px',
    },
  };

  return (
    <div>
      <button onClick={handleClick} style={cardStyles.signUpButton}>
        Sign Up
      </button>
      <div
        style={cardStyles.overlay}
        onClick={handleCardClose}
        ref={overlayRef}
      >
        {showCard && (
          <div style={cardStyles.cardContainer} onClick={(e) => e.stopPropagation()}>
            <span style={cardStyles.closeButton} onClick={handleCardClose}>
              &times;
            </span>
            <div style={cardStyles.content}>
              <div style={cardStyles.fintuitionText}>FINTUITION</div>
              <div style={cardStyles.taglineText}>BECOME A MORE CONFIDENT INVESTOR</div>
              <div style={{ position: 'relative', justifyContent: 'center', left: '62px' }}>
              </div>
              <label style={cardStyles.inputFieldLabel} htmlFor="email">
                E-Mail Address
              </label>
              <input type="email" placeholder="Email Address" onChange={handleEmailChange} style={cardStyles.inputField} />
              <label style={cardStyles.inputFieldLabel} htmlFor="email">
                Password
              </label>
              <input type="password" placeholder="Password" onChange={handlePasswordChange} style={cardStyles.inputField} />
              <div style={{ position: 'relative', top: '20%', bottom: '-80px' }}>
                <button
                  onClick={handleSignUpWithDelay}
                  style={{ width: '100%', padding: '10px', bottom: '30px', position: 'relative', backgroundColor: '#3498db', color: 'white' }}
                >
                  Sign up with email
                </button>
              </div>
              {registrationSuccess && (
                <div style={cardStyles.checkmarkOverlay}>
                  <FaCheck style={cardStyles.checkmark} />
                </div>
              )}
              {showError && (
                <div style={cardStyles.errorBox}>
                  <p style={cardStyles.errorMessage}>{errorMessage}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const LogInButton = () => {
  const [showCard, setShowCard] = useState(false);
  const overlayRef = useRef(null);
  const [showError, setShowError] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false); // New state for registration success
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const isAuthenticated = useSelector((state) => state.isAuthenticated); // Get the isAuthenticated value from the store
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(''); // Add error message state

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  useEffect(() => {
    if (registrationSuccess) {
      const delay = 150; // 5 seconds
      const timeoutId = setTimeout(() => {
        navigate('/profile');
        dispatch(setAuthenticated(true)); // Dispatch the action to set isAuthenticated to true
      }, delay);

      return () => clearTimeout(timeoutId);
    }
  }, [registrationSuccess, navigate]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleLogIn = async () => {
    const registrationData = {
      email,
      password,
    };

    try {
      const response = await fetch('https://www.fintuition.org/api/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData),
      });
      const responseData = await response.json();
      if (response.ok) {
        setRegistrationSuccess(true); // Set the registration success state to true
        dispatch(setUser(responseData.email)); // Assuming that the user information is stored in responseData.user
        // User successfully registered
        // You can perform additional actions here, such as redirecting to another page
      } else {
        // Handle error response
        console.log('LOGIN FAILED');
        // Display the error messages to the user
      }
    } catch (error) {
      console.log('Error:', error);
      setErrorMessage('Error with registration.');
      setShowError(true); // Show the error box
    }
  };

  useEffect(() => {
    const overlayElement = overlayRef.current;

    const flipInAnimation = anime.timeline({
      targets: overlayElement,
      autoplay: false,
    });

    flipInAnimation
      .add({
        duration: 400,
        opacity: [0, 1],
        easing: 'easeInOutQuad',
      })
      .add({
        duration: 800,
        rotateY: [180, 0],
        easing: 'easeInOutQuad',
      }, '-=500') // Start the rotation animation 500ms before the opacity animation ends
      .add({
        begin: () => {
          overlayElement.style.display = 'flex';
        },
        duration: 1,
      });

    if (showCard) {
      flipInAnimation.play();
    }
  }, [showCard]);

  const handleClick = () => {
    setShowCard(!showCard);
  };

  const handleLogInWithDelay = async () => {
    await handleLogIn(); // First, handle the sign-up logic
  };

  const handleCardClose = () => {
    setShowCard(false);
  };

  const cardStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      display: showCard ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
    },
    cardContainer: {
      width: '20%',
      height: showError ? '470px' : '400px', // Set a fixed height for the card
      background: '#000',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      padding: '20px',
      textAlign: 'center',
      justifyContent: 'center',
      position: 'relative',
      transformStyle: 'preserve-3d',
      transition: 'transform 0.5s', // Add transition property
      transform: `rotateY(${showCard ? '0' : '180deg'})`, // Apply the rotation
      opacity: showCard ? 1 : 0, // Set opacity based on showCard
    },
    content: {
      opacity: showCard ? 1 : 0, // Set opacity based on showCard
      transform: `rotateY(${showCard ? '0' : '-180deg'})`, // Apply opposite rotation for the content
    },
    errorBox: {
      backgroundColor: 'black',
      border: '1px solid red',
      padding: '10px',
      top: '70px',
      position: 'relative',
      marginTop: '4 0px',
      textAlign: 'center',
    },
    loginButton: {
      width: '100px', // Set the width to make the button smaller
      height: '30px', // Set the height to make the button smaller
      backgroundColor: 'grey', // Use light blue as the background color
      border: 'none', // Remove the border
      borderRadius: '10px', // Add a slight border radius for a rounded appearance
      right: '7%',
      overflow: 'hidden',
      display: 'flex', // Use flex display
      justifyContent: 'center', // Center horizontally
      alignItems: 'center', // Center vertically
      fontSize: '18px', // Adjust font size
      fontWeight: 'bold', // Set font weight to bold
      fontFamily: 'Lato',
      position: 'absolute',
      top: '17.5px',
    },
    fintuitionText: {
      fontFamily: 'Lato',
      fontSize: '25.2px',
      background: 'linear-gradient(to right, #3498db, #2ecc71)',
      WebkitBackgroundClip: 'text',
      color: 'transparent',
      marginBottom: '50px',
      justifyContent: 'center',
      position: 'relative',
      left: '5px',
    },
    taglineText: {
      fontFamily: 'Lato',
      fontSize: '16px',
      justifyContent: 'center',
      color: '#7b8793',
      top: '-40px',
      position: 'relative',
    },
    checkmarkOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent black background
      display: registrationSuccess ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
    },
    checkmark: {
      fontSize: '10rem',
      color: 'green',
    },
    inputField: {
      width: '100%',
      padding: '10px',
      margin: '10px 0',
      fontSize: '14px',
      border: 'none',
      borderRadius: '5px',
      background: '#34495e',
      color: '#ecf0f1',
      bottom: '0px',
      position: 'relative',
    },
    inputFieldLabel: {
      color: '#ecf0f1',
      textAlign: 'left',
      marginBottom: '5px',
      display: 'block',
      bottom: '0px',
      fontFamily: 'Lato',
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      fontSize: '18px',
      color: '#ecf0f1',
      cursor: 'pointer',
    },
    signUpWithEmailButton: {
      padding: '10px',
      fontSize: '16px',
      marginTop: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#3498db',
      color: '#ecf0f1',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    orText: {
      margin: '10px 0',
      position: 'relative',
      color: '#7b8793',
      bottom: '-20px',
    },
    errorMessage: {
      color: 'red',
      fontFamily: 'Lato',
      fontSize: '14px',
      marginBottom: '200px',
    },
  };

  return (
    <div>
      <button onClick={handleClick} style={cardStyles.loginButton}>
        Log In
      </button>
      <div
        style={cardStyles.overlay}
        onClick={handleCardClose}
        ref={overlayRef}
      >
        {showCard && (
          <div style={cardStyles.cardContainer} onClick={(e) => e.stopPropagation()}>
            <span style={cardStyles.closeButton} onClick={handleCardClose}>
              &times;
            </span>
            <div style={cardStyles.content}>
              <div style={cardStyles.fintuitionText}>FINTUITION</div>
              <div style={cardStyles.taglineText}>BECOME A MORE CONFIDENT INVESTOR</div>
              <div style={{ position: 'relative', justifyContent: 'center', left: '62px' }}>
              </div>
              <label style={cardStyles.inputFieldLabel} htmlFor="email">
                E-Mail Address
              </label>
              <input type="email" placeholder="Email Address" onChange={handleEmailChange} style={cardStyles.inputField} />
              <label style={cardStyles.inputFieldLabel} htmlFor="email">
                Password
              </label>
              <input type="password" placeholder="Password" onChange={handlePasswordChange} style={cardStyles.inputField} />
              <div style={{ position: 'relative', top: '20%', bottom: '-80px' }}>
                <button
                  onClick={handleLogInWithDelay}
                  style={{ width: '100%', padding: '10px', bottom: '30px', position: 'relative', backgroundColor: '#3498db', color: 'white' }}
                >
                  Log in
                </button>
              </div>
              {registrationSuccess && (
                <div style={cardStyles.checkmarkOverlay}>
                  <FaCheck style={cardStyles.checkmark} />
                </div>
              )}
              {showError && (
                <div style={cardStyles.errorBox}>
                  <p style={cardStyles.errorMessage}>{errorMessage}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ScreenerAnimation = () => {
  React.useEffect(() => {
    const animationContainer = document.getElementById('screener-animation-container');
    if (animationContainer) {
      Lottie.loadAnimation({
        container: animationContainer,
        renderer: 'svg', // You can choose the renderer (svg/canvas/html)
        loop: true,
        autoplay: true,
        animationData: StatsAnimation,
      });
    }
  }, []);

  const animationStyles = {
    width: '500px',
    height: '500px',
    fontSize: '200px',
    position: 'absolute',
    left: '50%', // Set left to 50% of the container's width
    top: '40%', // Set top to 50% of the container's height
    transform: 'translate(-50%, -50%)', // Translate the element back by half of its width and height
    textAlign: 'center', // Optionally center the text horizontally
  };

  return (
    <div style={animationStyles}>
      <div id="screener-animation-container" />
    </div>
  );
};

const ScreenerLoadingAnimation = () => {
  React.useEffect(() => {
    const animationContainer = document.getElementById('screener-animation-container-2');
    if (animationContainer) {
      Lottie.loadAnimation({
        container: animationContainer,
        renderer: 'svg', // You can choose the renderer (svg/canvas/html)
        loop: true,
        autoplay: true,
        animationData: LoadingGear,
      });
    }
  }, []);

  const animationStyles = {
    width: '170px',
    height: '170px',
    fontSize: '200px',
    position: 'absolute',
    left: '43%', // Set left to 50% of the container's width
    top: '40%', // Set top to 50% of the container's height
    transform: 'translate(-50%, -50%)', // Translate the element back by half of its width and height
    textAlign: 'center', // Optionally center the text horizontally
  };

  return (
    <div style={animationStyles}>
      <div id="screener-animation-container-2" />
    </div>
  );
};

const LoadingAnimationLottie = () => {
  React.useEffect(() => {
    const animationContainer = document.getElementById('animation-container');
    if (animationContainer) {
      Lottie.loadAnimation({
        container: animationContainer,
        renderer: 'svg', // You can choose the renderer (svg/canvas/html)
        loop: true,
        autoplay: true,
        animationData: LoadingAnimation,
      });
    }
  }, []);

  const loadingStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  };

  const spinnerStyles = {
    width: '200px',
    height: '200px',
    background: 'transparent',
  };

  return (
    <div style={loadingStyles}>
      <div id="animation-container" style={spinnerStyles} />
    </div>
  );
};

const AboutUsBody = () => {
  const boxRef = useRef(null); // Reference for the rectangular box
  const textRef = useRef(null); // Reference for the text
  const textBoxRef = useRef(null); // Reference for the new text box
  const detailedTextRef = useRef(null);

  useEffect(() => {
    // Animation for both the box and the text to move down
    anime({
      targets: [boxRef.current, textRef.current],
      translateY: [0, 20], // Moves down from its original position by 20px
      opacity: [0, 1], // Optionally, fade in from transparent to opaque
      duration: 1500, // Duration of the animation in milliseconds
      easing: 'easeOutExpo', // Easing function for the animation
    });

    anime({
      targets: detailedTextRef.current,
      translateY: [20, 0], // Moves down from its original position by 20px
      opacity: [0, 1], // Optionally, fade in from transparent to opaque
      duration: 1500, // Duration of the animation in milliseconds
      easing: 'easeOutExpo', // Easing function for the animation
    });

    anime({
      targets: textBoxRef.current,
      translateY: [20, 0], // Moves up from a lower position
      opacity: [0, 1], // Fades in
      duration: 1500,
      easing: 'easeOutExpo',
    });
  }, []);

  const bulletPointStyle = {
    display: 'inline-block',
    backgroundColor: 'lightblue', // Changed to light blue
    width: '20px',
    height: '20px',
    marginRight: '15px', // Increased right margin for more space between the box and the text
    verticalAlign: 'middle', // Ensures the box is aligned in the middle of the text line
  };

  const textStyle = {
    display: 'inline-block',
    color: '#f5f7fa',
    fontSize: '35px',
    fontWeight: 'bolder',
    position: 'relative',
    fontFamily: 'Lato',
    verticalAlign: 'middle', // Ensures text aligns with the middle of the box
    lineHeight: '20px', // Adjust lineHeight to match the box height if necessary
  };

  const detailedTextStyle = {
    display: 'block', // Ensures the text is in block format for proper wrapping
    color: '#e5e8eb',
    fontSize: '21px', // Suitable for readability
    fontFamily: 'Lato',
    lineHeight: '2', // Enhances readability with proper line spacing
    maxWidth: '50%', // Limits text extension to half the page width before wrapping
    fontWeight: 'bolder',
    position: 'absolute',
    top: '70px', // Adjusts vertical position relative to its container
    verticalAlign: 'middle', // Ensures vertical alignment with other elements, if necessary
    textAlign: 'left', // Aligns text to the left
    right: '150px',
  };
  const detailedText = 'In our view, buying a company that is trading below its intrinsic value is the best kind of investment. We offer a multitude of financial tools designed to help you find such companies and invest with confidence.';

  // Styles to position the component towards the bottom of the page
  const containerStyle = {
    position: 'absolute',
    bottom: '370px', // Adjust the distance from the bottom of the page
    right: '510px',
    width: '100%', // Ensures the content is centered if using text-align
    textAlign: 'center', // Centers the content within the container
  };

  return (
    <div style={containerStyle}>
      <span ref={boxRef} style={bulletPointStyle}></span>
      <span ref={textRef} style={textStyle}>Our core belief</span>
      <div>
        <span ref={detailedTextRef} style={detailedTextStyle}>{detailedText}</span>
      </div>
      <FoundedBox />
    </div>
  );
};

const FoundedBox = () => {
  const boxRef = useRef(null); // Reference for the animation

  useEffect(() => {
    // Animation to move the box from up to down
    anime({
      targets: boxRef.current,
      translateY: [-20, 0], // Starts 20px above its original position and moves down
      opacity: [0, 1], // Starts from transparent and fades in to full opacity
      easing: 'easeOutQuad', // Smooth easing for the animation
      duration: 1000, // Animation duration in milliseconds
    });
  }, []);

  // Maintain the existing boxStyle without changes
  const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '2rem',
    width: '20rem',
    height: '150px',
    backgroundColor: 'rgba(0, 0, 0, 30%)',
    color: 'white',
    fontFamily: 'Lato, sans-serif',
    fontSize: '20px', // Default font size for the box
    top: '-280px',
    position: 'absolute',
    right: '-410px',
  };

  // Define style for the 'FOUNDED' text
  const foundedStyle = {
    color: '#ffffff', // White color
    fontSize: '42px', // 42px font size
    fontWeight: '700', // Bold weight
    lineHeight: '42px', // Match font size for consistency
  };

  // Define style for the '2024' text
  const yearStyle = {
    color: 'lightgrey', // Light grey color
    fontSize: '21px', // 21px font size
    fontWeight: '300', // Light weight
    lineHeight: '19.999px', // Slightly smaller line height as per your specification
  };

  return (
    <div ref={boxRef} style={boxStyle}>
      <div style={yearStyle}>FOUNDED</div> {/* Apply yearStyle to '2024' */}
      <div style={foundedStyle}>2023</div> {/* Apply foundedStyle to 'FOUNDED' */}
    </div>
  );
};

const AboutUsHeader = () => {
  const textRef = useRef(null);
  const foundedBoxRef = useRef(null); // Reference for the new rectangular box

  useEffect(() => {
    textRef.current.style.width = '0';
    textRef.current.style.overflow = 'hidden'; // Ensure the text is clipped during animation
    textRef.current.style.display = 'inline-block'; // Necessary for width animation to work
    textRef.current.style.whiteSpace = 'nowrap'; // Prevents the text from wrapping

    anime({
      targets: textRef.current,
      width: ['0', '100%'], // Animate width from 0 to 100% of its full width
      opacity: [0, 1], // Fade in for smooth appearance
      easing: 'easeInOutQuad',
      duration: 3500,
      autoplay: true,
    });

    anime({
      targets: foundedBoxRef.current,
      translateY: [-20, 0], // Move from up (-20px) to its original position (0)
      opacity: [0, 1], // Fade in effect
      easing: 'easeOutElastic', // For a bit of bounce effect
      duration: 1000, // Duration of the animation
      delay: 500, // Start after a slight delay
    });
  }, []);

  const gradientTextStyle = {
    background: 'linear-gradient(to right, #8bfffb, #2874ff)',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent',
    display: 'block', // Changed from inline to block for better control and appearance
    fontSize: '45px',
    marginLeft: '373px',
    textAlign: 'center', // Ensure it's centered like the title
    fontWeight: 'bold', // Optional, for better visual impact
  };

  return (
    <div style={{ overflow: 'hidden', position: 'relative', width: '100%', top: '25%', right: '30%', textAlign: 'center' }}>
      <h1 ref={textRef} style={{ opacity: 0, position: 'relative', display: 'inline-block' }}>
        <span style={{ fontWeight: 'bolder', letterSpacing: '1.4px', fontFamily: 'Lato', color: '#f5f7fa', display: 'inline-block', fontSize: '45px' }}>FINTUITION</span>
      </h1>
      <div style={gradientTextStyle}>STOCK VALUATION PLATFORM</div>
    </div>
  );
};

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isArrowDown, setIsArrowDown] = useState(true);
  const [isDropdownOpen, setDropDownOpen] = useState(false); // State to manage dropdown visibility

  const toggleArrow = () => {
    setIsArrowDown(!isArrowDown);
  };
  const toggleDropdown = () => {
    setDropDownOpen(!isDropdownOpen); // Toggle dropdown visibility
    dispatch(setIsDropdownOpen(!isDropdownOpen)); // Dispatch action to update Redux store
  };
  const handleToolsClick = () => {
    toggleArrow();
    toggleDropdown();
    // Add additional functionality here if needed
  };
  const arrowStyle = {
    transform: isArrowDown ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: 'transform 0.3s ease',
  };
  const isAuthenticated = useSelector((state) => state.isAuthenticated); // Get the isAuthenticated value from the store
  const [showSignUpOverlay, setShowSignUpOverlay] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Add error message state
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false); // New state for registration success
  const isDropDownOpen = useSelector((state) => state.isDropdownOpen);

  const handleSignUpClick = () => {
    setShowSignUpOverlay(true);
    document.body.style.overflow = 'hidden'; // Prevent scrolling
  };
  const handleSignUp = async () => {
    const registrationData = {
      email,
      password,
    };

    try {
      const response = await fetch('https://www.fintuition.org/api/register/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData),
      });
      const responseData = await response.json();
      if (response.ok) {
        // User successfully registered
        // You can perform additional actions here, such as redirecting to another page
        setRegistrationSuccess(true); // Set the registration success state to true
        setShowError(false);
        dispatch(setUser(responseData.email)); // Assuming that the user information is stored in responseData.user
        dispatch(setAuthenticated(true)); // Dispatch the action to set isAuthenticated to true
        setTimeout(() => {
          navigate('/profile');
        }, 2000); // 5000 milliseconds = 5 seconds
      } else {
        // Handle error response
        const data = await response.json();
        // console.log(data);
        if (data.email) {
          if (data.email[0] === 'custom user with this email already exists.') {
            setErrorMessage('Please enter an unused email.');
            setShowError(true); // Show the error box
          } else if (data.email[0] === 'Enter a valid email address.') {
            setErrorMessage('Please enter a valid email address.');
            setShowError(true); // Show the error box
          }
        } else {
          setErrorMessage(data.email || data.password || 'An error occurred during registration.');
          setShowError(true); // Show the error box
        }
        // Display the error messages to the user
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  const handleSignOut = async () => {
    try {
      const response = await fetch('https://www.fintuition.org/api/logout/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        // User successfully logged out
        // You can perform additional actions here, such as updating the Redux store
        dispatch(setAuthenticated(false)); // Dispatch the action to set isAuthenticated to false
        dispatch(setUser(null)); // Clear the user information in the Redux store
        navigate('/'); // Redirect to the home page or another appropriate page
      } else {
        // Handle error response
        console.log('Error logging out');
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };
  const handleCloseOverlay = () => {
    setShowSignUpOverlay(false);
    document.body.style.overflow = 'auto'; // Restore scrolling
  };
  const styles = {
    button: {
      width: '100px', // Set the width to make the button smaller
      height: '30px', // Set the height to make the button smaller
      backgroundColor: '#0800F0', // Use light blue as the background color
      border: 'none', // Remove the border
      borderRadius: '4px', // Add a slight border radius for a rounded appearance
      right: '230px',
      display: 'flex', // Use flex display
      justifyContent: 'flex-start', // Center horizontally
      alignItems: 'center', // Center vertically
      fontSize: '18px', // Adjust font size
      fontWeight: 'bold', // Set font weight to bold
      fontFamily: 'Lato',
      position: 'absolute',
      top: '17.5px',
    },
    loginButton: {
      width: '100px', // Set the width to make the button smaller
      height: '30px', // Set the height to make the button smaller
      backgroundColor: 'grey', // Use light blue as the background color
      border: 'none', // Remove the border
      borderRadius: '4px', // Add a slight border radius for a rounded appearance
      display: 'flex', // Use flex display
      justifyContent: 'flex-start', // Center horizontally
      alignItems: 'center', // Center vertically
      fontSize: '18px', // Adjust font size
      fontWeight: 'bold', // Set font weight to bold
      fontFamily: 'Lato',
      position: 'absolute',
      top: '17.5px',
    },
    profilePage: {
      width: '100px', // Set the width to make the button smaller
      height: '30px', // Set the height to make the button smaller
      backgroundColor: 'grey', // Use light blue as the background color
      border: 'none', // Remove the border
      borderRadius: '4px', // Add a slight border radius for a rounded appearance
      right: '120px',
      display: 'flex', // Use flex display
      justifyContent: 'flex-start', // Center horizontally
      alignItems: 'center', // Center vertically
      fontSize: '18px', // Adjust font size
      fontWeight: 'bold', // Set font weight to bold
      fontFamily: 'Lato',
      position: 'absolute',
      top: '17.5px',
    },
    myForm: {
      marginTop: '20px',
      position: 'absolute',
      right: '20%',
      top: '-1.9px',
    },
    homeText: {
      fontFamily: "'Lato', sans-serif", // Replace 'Your Font' with the font name from the Google Font URL
      left: '100px',
      fontSize: '30px',
      marginLeft: '-375px',
      top: '-12px',
      color: '#65DAF0',
      position: 'absolute',
    },
    arrow: {
      transform: isArrowDown ? 'rotate(0deg)' : 'rotate(360deg)',
      transition: 'transform 0.3s ease',
      fontSize: '16px', // Adjust the font size to make the arrow smaller
    },
    toolsText: {
      fontFamily: "'Lato', sans-serif", // Replace 'Your Font' with the font name from the Google Font URL
      left: '-130px',
      fontSize: '20px',
      marginRight: '100px',
      top: '-9px',
      color: '#65DAF0',
      position: 'absolute',
      verticalAlign: 'top',
    },
    aboutUsText: {
      fontFamily: "'Lato', sans-serif", // Replace 'Your Font' with the font name from the Google Font URL
      fontSize: '20px',
      left: '-10px',
      top: '-9px',
      color: '#65DAF0',
      position: 'absolute',
      verticalAlign: 'top',
    },
    toolsHeader: {
      left: '70px',
      fontFamily: "'Lato', sans-serif", // Replace 'Your Font' with the font name from the Google Font URL
      fontSize: '20px',
      top: '-9px',
      color: '#65DAF0',
      display: 'flex', // Display the text and arrow as flex items
      justifyContent: 'space-between', // Add space between items
      position: 'absolute',
      verticalAlign: 'top',
    },
    pricingHeader: {
      left: '150px',
      fontFamily: "'Lato', sans-serif", // Replace 'Your Font' with the font name from the Google Font URL
      fontSize: '20px',
      top: '-9px',
      color: '#65DAF0',
      position: 'absolute',
      verticalAlign: 'top',
    },
    card: {
      backgroundColor: 'black',
      color: 'white',
      width: '300px', // Adjust the width as needed
      height: '200px', // Adjust the height as needed
      borderRadius: '10px', // Add rounded corners
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
    },
    overlayCard: {
      backgroundColor: 'black',
      color: 'white',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 999,
      transform: `perspective(800px) rotateY(${showSignUpOverlay ? '0' : '180'}deg)`,
      transformOrigin: 'center',
      visibility: showSignUpOverlay ? 'visible' : 'hidden',
      transition: 'transform 0.5s ease-in-out, visibility 0.5s ease-in-out',
    },
    googleButton: {
      backgroundColor: '#fff', // Customize the button background color
      color: '#000', // Customize the button text color
      border: 'none',
      padding: '10px 20px',
      fontSize: '16px',
      cursor: 'pointer',
      marginTop: '10px', // Adjust the margin as needed
    },

    errorBox: {
      backgroundColor: 'white',
      border: '1px solid red',
      padding: '10px',
      marginTop: '4 0px',
      textAlign: 'center',
    },
    toolsDropdownContent: {
      position: 'absolute',
      top: '100%',
      left: 0,
      width: '100vw',
      height: '25vh',
      backgroundColor: '#fff',
      zIndex: 999,
      border: '1px solid #ccc',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },

    errorMessage: {
      color: 'red',
      fontFamily: 'Lato',
      fontSize: '14px',
      marginBottom: '200px',
    },

  };
  const [toggleMenu, setToggleMenu] = useState(false);
  // eslint-disable-next-line no-restricted-globals
  // console.log(`Screen resolution: ${screen.width}x${screen.height}`);
  const handleProfileClick = () => {
    // Redirect to the /profile page when the button is clicked
    navigate('/profile');
  };

  function handleLogoClick() {
    navigate('/');
  }

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={FintuitionLogo} style={{ marginTop: '4.0px' }} onClick={handleLogoClick} />
        </div>
        <div className="gpt3__navbar-links_container" style={styles.linksContainer}>
          <p style={styles.homeText}><a href="/">Fintuition</a></p>
        </div>
        <div className="gpt3__navbar-links_container" style={styles.linksContainer}>
          <p style={styles.toolsText}><a href="/">Dashboard</a></p>
        </div>
        <div className="gpt3__navbar-links_container" style={styles.linksContainer}>
          <p style={styles.aboutUsText}><a href="/about">About</a></p>
        </div>
        <div className="gpt3__navbar-links_container">
          <p style={styles.toolsHeader} onClick={handleToolsClick}>
            Tools <span style={styles.arrow}>{isDropDownOpen ? '▲' : '▼'}</span>
          </p>
        </div>
        <div className="gpt3__navbar-links_container" style={styles.linksContainer}>
          <p style={styles.pricingHeader}><a href="/pricing">Pricing</a></p>
        </div>
      </div>
      <div className="gpt3__navbar-sign">
        <div style={styles.myForm}>
          {location.pathname !== '/' && <NewForm />}
        </div>
        {isAuthenticated ? ( // Check if the user is authenticated
          <div>
            <button type="button" style={styles.button} onClick={handleSignOut}>
              Log out
            </button>
            <button type="button" style={styles.profilePage} onClick={handleProfileClick}>
              Profile
            </button>
          </div>
        ) : (
          <div>
            <SignUpButton />
            <LogInButton />
          </div>
        )}
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu}
      </div>
    </div>
  );
};

const ToolsDropDown = () => {
  const dispatch = useDispatch();
  const isDropDownOpen = useSelector((state) => state.isDropdownOpen);
  const dropdownRef = useRef(null);
  const toolsButtonRef = useRef(null);
  const timerRef = useRef(null); // Ref to hold the timer

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropDownOpen) {
        dispatch(setIsDropdownOpen(false));
      }
    };

    const handleMouseMoveInside = () => {
      // If mouse is inside, reset the timer
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        if (isDropDownOpen) {
          dispatch(setIsDropdownOpen(false));
        }
      }, 5000); // 5 seconds timeout
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Add mousemove listener for inside the dropdown if dropdownRef exists
    if (dropdownRef.current) {
      dropdownRef.current.addEventListener('mousemove', handleMouseMoveInside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      // Remove mousemove listener only if dropdownRef exists
      if (dropdownRef.current) {
        dropdownRef.current.removeEventListener('mousemove', handleMouseMoveInside);
      }
      clearTimeout(timerRef.current);
    };
  }, [dispatch, isDropDownOpen]);

  useEffect(() => {
    if (isDropDownOpen) {
      anime({
        targets: dropdownRef.current,
        opacity: [0, 1],
        duration: 500, // Animation duration in milliseconds
        easing: 'easeInOutQuad', // Animation easing function
      });
    }
  }, [isDropDownOpen]);

  const toggleDropdown = () => {
    dispatch(setIsDropdownOpen(!isDropDownOpen));
  };

  const handleClickInside = () => {
    // Toggle dropdown only if it's not already open
    if (!isDropDownOpen) {
      toggleDropdown();
    }
  };

  const [isHovered, setIsHovered] = useState(false); // State to track hover
  const [isDCFValueHovered, setIsDCFValueHovered] = useState(false); // State for DCF Value button hover
  const [isTradeFinderHovered, setIsTradeFinderHovered] = useState(false); // State for Trade Finder button hover

  const [styles] = useState({
    toolsDropdownContent: {
      backgroundColor: 'black',
      zIndex: '999',
      position: 'absolute',
      width: '100%',
      height: '25%',
      pointerEvents: isDropDownOpen ? 'auto' : 'none',
      opacity: isDropDownOpen ? 1 : 0, // Set initial opacity based on dropdown state
    },
    toolsText: {
      padding: '10px',
      display: 'inline',
      background: 'linear-gradient(109.6deg, rgba(156,252,248,1) 15.2%, rgb(102 156 255) 91.1%)',
      WebkitBackgroundClip: 'text',
      color: 'transparent',
      fontFamily: 'Lato',
      left: '1.5%',
      position: 'absolute',
      fontSize: '26px',
    },
    intrinsicValueText: {
      padding: '10px',
      display: 'inline',
      background: 'linear-gradient(109.6deg, rgba(156,252,248,1) 11.2%, rgb(102 156 255) 91.1%)',
      WebkitBackgroundClip: 'text',
      color: 'transparent',
      fontFamily: 'Lato',
      left: '29.9%',
      position: 'absolute',
      fontSize: '26px',
    },
    allToolsText: {
      padding: '10px',
      display: 'inline',
      background: 'linear-gradient(109.6deg, rgba(156,252,248,1) 11.2%, rgb(102 156 255) 91.1%)',
      WebkitBackgroundClip: 'text',
      color: 'transparent',
      fontFamily: 'Lato',
      left: '58.5%',
      position: 'absolute',
      fontSize: '26px',
    },
    horizontalLine: {
      border: '0',
      left: '2%',
      top: '19%',
      height: '1px',
      position: 'absolute',
      background: 'rgba(211, 211, 211, 0.25)', // Light grey color with 30% opacity
      width: '20%',
    },
    horizontalLine2: {
      border: '0',
      left: '30.5%',
      top: '19%',
      height: '1px',
      position: 'absolute',
      background: 'rgba(211, 211, 211, 0.25)', // Light grey color with 30% opacity
      width: '20%',
    },
    horizontalLine3: {
      border: '0',
      left: '59%',
      top: '19%',
      height: '1px',
      position: 'absolute',
      background: 'rgba(211, 211, 211, 0.25)', // Light grey color with 30% opacity
      width: '20%',
    },
    discoverText: {
      fontFamily: 'Lato',
      color: '#cad1d8',
      fontSize: '15.4px',
      position: 'absolute',
      top: '21.5%',
      left: '2%',
    },
    intrinsicValueTextFooter: {
      fontFamily: 'Lato',
      color: '#cad1d8',
      fontSize: '15.4px',
      position: 'absolute',
      top: '21.5%',
      left: '30.5%',
    },
    button: {
      backgroundColor: 'rgba(0,0,0,.6)',
      color: 'white',
      border: 'none',
      padding: '10px 20px',
      fontFamily: 'Lato',
      fontSize: '15.4px',
      cursor: 'pointer',
      position: 'absolute',
      top: '33%',
      left: '2%',
      width: '20%',
      background: '#145aa1',
    },
    dcfButton: {
      backgroundColor: 'rgba(0,0,0,.6)',
      color: 'white',
      border: 'none',
      padding: '10px 20px',
      fontFamily: 'Lato',
      fontSize: '15.4px',
      cursor: 'pointer',
      position: 'absolute',
      top: '33%',
      left: '30.5%',
      width: '20%',
      background: '#145aa1',
    },
    discountRateButton: {
      background: '#1F2932', // Extremely light grey background
      border: 'none',
      padding: '8px 10px', // Reduced padding vertically
      fontFamily: 'Lato',
      fontSize: '15.4px',
      color: 'white',
      cursor: 'pointer',
      position: 'absolute',
      top: '21%',
      left: '59%',
      width: '20%',
      textAlign: 'left',
      transition: 'transform 0.2s', // Smooth transition on hover
    },
    discountRateButtonHover: {
      transform: 'scale(1.05)', // Slightly increase in size on hover
    },
    dcfValueButton: {
      background: '#1F2932', // Extremely light grey background
      border: 'none',
      padding: '8px 10px', // Reduced padding vertically
      fontFamily: 'Lato',
      fontSize: '15.4px',
      color: 'white',
      cursor: 'pointer',
      position: 'absolute',
      top: '46%',
      left: '59%',
      width: '20%',
      textAlign: 'left',
      transition: 'transform 0.2s', // Smooth transition on hover
    },
    tradeFinderButton: {
      background: '#1F2932', // Extremely light grey background
      border: 'none',
      padding: '8px 10px', // Reduced padding vertically
      fontFamily: 'Lato',
      fontSize: '15.4px',
      color: 'white',
      cursor: 'pointer',
      position: 'absolute',
      top: '71%', // Adjust position based on your layout
      left: '59%',
      width: '20%',
      textAlign: 'left',
      transition: 'transform 0.2s', // Smooth transition on hover
    },
    dcfValueButtonHover: {
      transform: 'scale(1.05)', // Slightly increase in size on hover
    },
    tradeFinderButtonHover: {
      transform: 'scale(1.05)', // Slightly increase in size on hover
    },
    dcfValueButtonText: {
      color: 'white',
      fontSize: '12px',
      marginLeft: '10px', // Add some space between the start of the button and the text
    },
    allToolsTextStyle: {
      position: 'absolute',
      left: '85%',
      top: '14%', // Adjust the vertical position as needed
      color: '#cad1d8',
      fontSize: '18.8px',
      fontFamily: 'Lato',
      width: '200px', // Set a width to force wrapping
    },
    discountRateButtonText: {
      color: 'white',
      fontSize: '12px',
      marginLeft: '10px', // Add some space between the start of the button and the text
    },
    discountRateButtonHeader: {
      color: 'white',
      fontSize: '15.4px',
      marginLeft: '10px', // Add some space between the start of the button and the text
    },
    dcfCalcButtonHeader: {
      color: 'white',
      fontSize: '15.4px',
      marginLeft: '10px', // Add some space between the start of the button and the text
    },
    intrinsicValueButton: {
      background: '#1F2932', // Extremely light grey background
      border: 'none',
      padding: '8px 10px', // Reduced padding vertically
      fontFamily: 'Lato',
      fontSize: '15.4px',
      color: 'white',
      cursor: 'pointer',
      position: 'absolute',
      top: '90px',
      left: '1060px',
      width: '20%',
      textAlign: 'left',
      transition: 'transform 0.2s', // Smooth transition on hover
    },
    warrenBuffettImageContainer: {
      display: 'flex',
      marginLeft: '40px', // Adjust the margin-left as needed
      alignItems: 'center',
      marginTop: '10px', // Adjust the margin-top as needed
    },
    warrenBuffettImage: {
      width: '50px', // Adjust the width as needed
      borderRadius: '50%',
    },
    warrenBuffettText: {
      color: '#f5f7fa',
      marginLeft: '10px', // Adjust the margin-left as needed
    },
    quote: {
      fontSize: '52px', // Adjust the size of the icon as needed
      marginRight: '20px', // Adjust the spacing between the icon and the text as needed
    },
  });

  return (
    <div>
      <div ref={dropdownRef} style={styles.toolsDropdownContent} onClick={handleClickInside}>
        <p style={styles.toolsText}>Stock Screener</p>
        <hr style={styles.horizontalLine} />
        <p style={styles.discoverText}>Find stocks suited to your investment philosophy</p>
        <Link to="/screener">
          <button style={styles.button} onClick={toggleDropdown}>Open Stock Screener</button>
        </Link>
        <p style={styles.intrinsicValueText}>Intrinsic Value Calculator</p>
        <hr style={styles.horizontalLine2} />
        <p style={styles.intrinsicValueTextFooter}>Calculate the intrinsic value of any stock</p>
        <Link to="/dashboard">
          <button style={styles.dcfButton}>Open DCF Calculator</button>
        </Link>
        <p style={styles.allToolsText}>Other tools</p>
        <hr style={styles.horizontalLine3} />
        <div style={styles.allToolsTextStyle}>
          <p>
            <FontAwesomeIcon icon={faQuoteLeft} className="quoteIcon" style={{ marginRight: '5px', fontSize: '24px' }} />It&apos;s <span style={{ fontWeight: 'bold' }}>far better</span> to buy a wonderful company at a fair price than a fair company at a wonderful price.
          </p>
          <div style={styles.warrenBuffettImageContainer}>
            <img src="https://ik.imagekit.io/kkbzr2uz4cp//ceos/warren-buffett.png?tr=w-200" alt="Warren Buffett" style={styles.warrenBuffettImage} />
            <span style={styles.warrenBuffettText}>Warren Buffett</span>
          </div>
        </div>
        <Link to="/discount-rate-calculator">
          <button
            style={{
              ...styles.discountRateButton,
              ...(isHovered && styles.discountRateButtonHover), // Apply hover style if isHovered is true
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div style={styles.discountRateButtonHeader}>
              Discount Rate Calculator
              <FontAwesomeIcon icon={faPercent} style={{ color: 'blue', float: 'right' }} size="2x" />
            </div>
            <div style={styles.discountRateButtonText}>
              Calculate the discount rate for any stock
            </div>
          </button>
        </Link>
        <Link to="/dcf-value-calculator">
          <button
            style={{
              ...styles.dcfValueButton,
              ...(isDCFValueHovered && styles.dcfValueButtonHover), // Apply hover style if isDCFValueHovered is true
            }}
            onMouseEnter={() => setIsDCFValueHovered(true)}
            onMouseLeave={() => setIsDCFValueHovered(false)}
          >
            <div style={styles.dcfCalcButtonHeader}>
              DCF Value Calculator
              <FontAwesomeIcon icon={faCalculator} style={{ color: 'blue', float: 'right' }} size="2x" />
            </div>
            <div style={styles.dcfValueButtonText}>
              Build and customize your own model
            </div>
          </button>
        </Link>
        <Link to="/trade-finder">
          <button
            onClick={toggleDropdown}
            style={{
              ...styles.tradeFinderButton,
              ...(isTradeFinderHovered && styles.tradeFinderButtonHover), // Apply hover style if isTradeFinderHovered is true
            }}
            onMouseEnter={() => setIsTradeFinderHovered(true)}
            onMouseLeave={() => setIsTradeFinderHovered(false)}
          >
            <div style={styles.discountRateButtonHeader}>
              Trade Finder
              <FontAwesomeIcon icon={faDollarSign} style={{ color: 'blue', float: 'right' }} size="2x" />
            </div>
            <div style={styles.discountRateButtonText}>
              Find your next big trade
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
};

const HomePage = () => {
  const isDropDownTrue = useSelector((state) => state.isDropdownOpen); // Get the value of isDropdownOpen from the Redux store
  const dispatch = useDispatch(); // Get the dispatch function from React-Redux
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
        {isDropDownTrue && <ToolsDropDown />}
        <Header />
      </div>
    </div>
  );
};

const styles = {
  card: {
    backgroundColor: 'black',
    color: 'white',
    width: '300px', // Adjust the width as needed
    height: '200px', // Adjust the height as needed
    borderRadius: '10px', // Add rounded corners
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  cardContainer: {
    width: '60%',
    minWidth: '1000px',
    marginLeft: '20%',
    marginRight: '20%',
    backgroundColor: '#f0f0f0',
    padding: '20px',
    borderRadius: '20px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    minHeight: '180px',
    position: 'relative',
    background: 'linear-gradient(to bottom, black 0%, black 20%, #C0C0C0 20%, #C0C0C0 100%)',
    height: '200px',
    marginTop: '2%',
  },
  imageContainer: {
    position: 'absolute', // Create a new stacking context for the image
    marginTop: '-170px', // Add some space between the text and the image
    width: '56px', // Set the desired width for the image
    display: 'flex', // Use flexbox to align items side by side
    height: '56px', // Set the desired height for the image (assuming a square)
    alignItems: 'center', // Vertically align items to center
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Ensure the image fits the container without distortion
    borderRadius: '4px', // Add border radius for a rounded look (optional)
  },
  companyName: {
    marginBottom: '50%',
    marginLeft: '10px', // Add some spacing between the image and the additional text
    fontSize: '20px',
    fontFamily: "'Lato', sans-serif", // Replace 'Your Font' with the font name from the Google Font URL
    whiteSpace: 'nowrap', // Prevent the text from wrapping to the next line
    right: '20px',
  },
  textContainer: {
    position: 'relative',
    color: '#fff',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap', // Prevent the content from wrapping to the next line
    minWidth: '50%', // Set a minimum width for the text container
    top: '-10px',
  },
  value: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  otherText: {
    fontSize: '14px',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    top: '200px',
    right: '-15px',
  },
  placeholder: {
    fontSize: '14px',
    color: '#999',
  },
  metallicText: {
    fontSize: '14px',
    fontFamily: 'MetallicFont', // Replace 'MetallicFont' with the actual font family for the metallic font
    whiteSpace: 'nowrap', // Prevent the text from wrapping to the next line
    marginTop: '5px', // Add spacing between the companyName and the metallic text
    position: 'absolute',
    top: '20px',
    left: '67px',
    color: 'grey',
  },
  stockPriceChart: {
    width: '1000px',
    left: '50%',
  },
};

const Card = () => {
  const tickerData = useSelector((state) => state.tickerData);
  let humanReadableDateTime = '';
  if (tickerData) {
    const datetime = new Date(tickerData.updated_at);
    humanReadableDateTime = datetime.toLocaleString();
  }
  const mainString = tickerData?.imgUrl;
  const nasdaq = 'nasdaq';
  const nyse = 'nyse';
  let companySymbol = '';
  if (mainString.includes(nasdaq)) {
    companySymbol = `NASDAQ: ${tickerData.symbol}`;
  }
  if (mainString.includes(nyse)) {
    companySymbol = `NYSE: ${tickerData.symbol}`;
  }
  const StockPriceChart = () => {
    // Step 4: Define the data for the chart
    const stockPrices = tickerData?.prices;
    const dates = tickerData?.dates;

    const lastPrice = Number(tickerData?.prices[stockPrices.length - 1]);
    const firstPrice = Number(tickerData?.prices[0]);
    function isWithinRange(last, first) {
      // Calculate the lower and upper bounds for the range (95% and 105% of the baseValue)
      const lowerBound = last * 0.95;
      const upperBound = last * 1.05;
      // Check if the value is within the range
      return first >= lowerBound && last <= upperBound;
    }
    let theMarg = 0;
    let theColor = '#008000';
    if (lastPrice < firstPrice) {
      theColor = '#B20000';
    }
    if (isWithinRange(lastPrice, firstPrice)) {
      theMarg = 100;
    }
    // Step 5: Configure the options for the chart
    const options = {
      chart: {
        backgroundColor: 'transparent', // Set the background color of the chart to transparent
        height: 175, // Set the height of the chart container to 300px (adjust as needed)
        marginLeft: 120,
        marginTop: theMarg,
        width: 1000,
        minWidth: 1000,
      },
      title: {
        text: null, // Remove the title from the chart
      },
      legend: {
        enabled: false, // Hide the legend
      },
      xAxis: {
        categories: dates, // Use the dates as the x-axis categories
        labels: {
          enabled: false, // Hide the labels on the x-axis
        },
        gridLineWidth: 0, // Remove grid lines on the y-axis
      },
      yAxis: {
        title: {
          text: null, // Hide the label on the y-axis
        },
        labels: {
          enabled: false, // Hide the labels on the y-axis
        },
        gridLineWidth: 0, // Remove grid lines on the y-axis
      },
      series: [
        {
          name: 'Stock Price',
          data: stockPrices.map((price) => parseFloat(price)), // Convert stockPrices to numbers
          color: theColor,
        },
      ],
    };

    return (
      <div style={{ position: 'relative' }}>
        {/* Step 6: Render the HighchartsReact component */}
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  }
  const formattedDateStr = formatDate(humanReadableDateTime);
  return (
    <div style={styles.cardContainer}>
      {tickerData ? (
        <div>
          {tickerData?.bannerValid && (
            <div
              style={{
                position: 'absolute',
                top: -30,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100vw', // Use viewport width to cover the entire screen
                height: '125%', // Use 100% to match the card component's height
                backgroundImage: `url(${tickerData.bannerUrl})`,
                backgroundSize: 'cover',
                zIndex: -1,
              }}
            />
          )}
          <div style={styles.textContainer}>
            <p style={styles.value}>Stock price: {tickerData.stockPrice}</p>
            <p style={styles.otherText}>Updated on: {formattedDateStr}</p>
          </div>
          <div style={styles.stockPriceChart}>
            <StockPriceChart />
          </div>
          {/* Add the image container here */}
          <div style={styles.imageContainer}>
            <img
              src={tickerData.imgUrl}
              style={styles.image}
            />
            <p style={styles.companyName}>{tickerData.company_name?.replace('logo', '')}</p>
            <p style={styles.metallicText}>{companySymbol}</p>
          </div>
        </div>
      ) : (
        <p style={styles.placeholder}>No value available</p>
      )}
    </div>
  );
};

const CompetitorTitles = () => {
  const tickerData = useSelector((state) => state.tickerData);
  // console.log(tickerData);
  const companyName = tickerData?.company_name;
  const companyNameRl = companyName.replace('logo', '');
  const companyNameReal = companyNameRl.replace(/[,.]/g, '');
  const CompetitorTitlesStyles = {
    centeredContainer: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'left',
      paddingTop: '2%',
      height: '10px',
      position: 'absolute',
      marginLeft: '50px',
      marginBottom: '10%',
    },
    centeredText: {
      textAlign: 'left',
      fontSize: '20px',
      fontWeight: 'bold',
      color: 'white',
      fontFamily: "'Lato', sans-serif", // Replace 'Your Font' with the font name from the Google Font URL
    },
    greyNumber: {
      color: 'grey', // Set the color of the number to grey
      marginRight: '8px', // Add more space between the "1." and "DCF Value"
    },
  };

  return (
    <div style={CompetitorTitlesStyles.centeredContainer}>
      <p style={CompetitorTitlesStyles.centeredText}>
        {companyNameReal} Competitors:
      </p>
    </div>
  );
};

function removeDuplicates(tickers) {
  const uniqueTickers = [];
  const tickerIndexes = {};

  for (let i = 0; i < tickers.length; i += 1) {
    const ticker = tickers[i];

    if (Object.prototype.hasOwnProperty.call(tickerIndexes, ticker)) {
      // Remove the ticker with the lower index
      const indexToRemove = tickerIndexes[ticker];
      uniqueTickers[indexToRemove] = null;
    }

    // Update the latest index of the ticker
    tickerIndexes[ticker] = i;

    // Add the ticker to the uniqueTickers array if it's not null
    if (uniqueTickers[i] !== null) {
      uniqueTickers[i] = ticker;
    }
  }

  // Remove any null entries from the uniqueTickers array
  return uniqueTickers.filter((ticker) => ticker !== null);
}

const Tag = ({ text, onClose }) => {
  const [isHovered, setIsHovered] = useState(false);

  const tagStyle = {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '12px',
    padding: '5px 10px',
    marginRight: '5px',
    display: 'inline-block',
    cursor: 'pointer',
  };

  const closeIconStyle = {
    marginLeft: '5px',
    fontWeight: 'bold',
    cursor: 'pointer',
  };

  const handleTagClose = () => {
    onClose();
  };

  return (
    <div
      style={tagStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {text}
      <span style={closeIconStyle} onClick={handleTagClose}>X</span>
    </div>
  );
};

const Profitability = () => {
  const contentTextStyle = {
    color: '#1f2933',
    fontFamily: 'Lato',
    fontWeight: '700',
    fontSize: '23.1429px',
    lineHeight: '1.5',
  };

  return (
    <div style={contentTextStyle}>Profitability</div>
  );
};

const TechnicalIndicators = () => {
  const contentTextStyle = {
    color: '#1f2933',
    fontFamily: 'Lato',
    fontWeight: '700',
    fontSize: '23.1429px',
    lineHeight: '1.5',
  };

  return (
    <div style={contentTextStyle}>Technical Indicators</div>
  );
};

const MostPopular = () => {
  const contentTextStyle = {
    color: '#1f2933',
    fontFamily: 'Lato',
    fontWeight: '700',
    fontSize: '23.1429px',
    lineHeight: '1.5',
  };

  return (
    <div style={contentTextStyle}>Most Popular</div>
  );
};

const Valuation = () => {
  const contentTextStyle = {
    color: '#1f2933',
    fontFamily: 'Lato',
    fontWeight: '700',
    fontSize: '23.1429px',
    lineHeight: '1.5',
  };

  return (
    <div style={contentTextStyle}>Valuation</div>
  );
};

const WallStPriceTargets = () => {
  const contentTextStyle = {
    color: '#1f2933',
    fontFamily: 'Lato',
    fontWeight: '700',
    fontSize: '23.1429px',
    lineHeight: '1.5',
  };

  const HoverableCell = styled.div`
  color: #1f2933;
  font-family: Lato;
  font-weight: 700;
  font-size: 18.1429px;
  line-height: 1.5;
  padding: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f3f3f3; // Light grey background on hover
  }
`;

  const horizontalLineStyle = {
    marginTop: '-4px', // Adjust as needed for spacing
    height: '2px', // Thickness of the line
    backgroundColor: '#ccc', // Color of the line, change as needed
    width: '140%', // Ensures the line stretches to the width of its container
  };

  const scrollableSectionStyle = {
    overflowY: 'scroll', // Enable vertical scrolling
    maxHeight: '200px', // Maximum height before scrolling starts
    marginTop: '20px', // Space above the scrollable section
    padding: '10px', // Padding inside the scrollable section
  };

  return (
    <div>
      <div style={contentTextStyle}>Wall St Price Targets</div>
      <div style={horizontalLineStyle}></div>
    </div>
  );
};

const FiltersPopup = ({ isOpen, onClose }) => {
  const popupRef = useRef(null);
  const overlayRef = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState('Most Popular');

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const renderContent = () => {
    switch (selectedCategory) {
      case 'Most Popular':
        return <MostPopular />;
      case 'Valuation':
        return <Valuation />;
      case 'Wall St Price Targets':
        return <WallStPriceTargets />;
      case 'Profitability':
        return <Profitability />;
      case 'Technical Indicators':
        return <TechnicalIndicators />;
      default:
        return <div>Please select a category</div>;
    }
  };

  useEffect(() => {
    if (isOpen) {
      anime({
        targets: [overlayRef.current, popupRef.current],
        opacity: [0, 1],
        duration: 500,
        easing: 'easeInOutQuad',
      });
    }
  }, [isOpen]);

  // Close the popup when the overlay is clicked
  const handleOverlayClick = (event) => {
    if (event.target === overlayRef.current) {
      onClose();
    }
  };

  const filterTextStyle = {
    color: 'rgba(0, 0, 0, 0.87)', // Set text color to black
    fontSize: '19.6px / 27.44px (1.4)',
    fontWeight: '700',
    fontFamily: 'Lato',
    textAlign: 'left', // Align text to the left
    margin: '0', // Remove default margin
    marginBottom: '20px', // Add bottom margin for spacing
  };

  const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.9)', // Corrected the opacity to more standard value for overlays
    zIndex: 999, // Higher z-index than the popup
    display: isOpen ? 'block' : 'none',
    opacity: 0, // Initially set opacity to 0
  };

  const popupStyles = {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '20px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '60%',
    zIndex: 1000,
    display: isOpen ? 'block' : 'none',
    opacity: 0, // Initially set opacity to 0
  };

  const closeButtonStyles = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  };

  const categoryStyle = {
    cursor: 'pointer',
    margin: '10px 0',
    padding: '10px',
    borderRadius: '5px',
    color: '#1f2933',
    fontFamily: 'Lato',
    fontWeight: '300',
  };

  const contentTextStyle = {
    color: '#1f2933',
    fontFamily: 'Lato',
    fontWeight: '700',
    fontSize: '23.1429px',
    lineHeight: '1.5',
  };

  const horizontalLineStyle = {
    width: '100%',
    height: '1px',
    backgroundColor: '#9aa5b1',
    margin: '10px auto', // Adjust margin to position the line vertically
  };

  return (
    <>
      <div ref={overlayRef} style={overlayStyles} onClick={handleOverlayClick}></div>
      <div ref={popupRef} style={popupStyles}>
        <span style={closeButtonStyles} onClick={onClose}>X</span>
        <h3 style={filterTextStyle}>New Filter</h3>
        <div style={horizontalLineStyle}></div>
        <div style={{ display: 'flex', marginTop: '70px' }}>
          <div style={{ flex: 1 }}>
            <div style={categoryStyle} onClick={() => handleCategoryClick('Most Popular')}>
              Most Popular
            </div>
            <div style={categoryStyle} onClick={() => handleCategoryClick('Valuation')}>
              Valuation
            </div>
            <div style={categoryStyle} onClick={() => handleCategoryClick('Wall St Price Targets')}>
              Wall St Price Targets
            </div>
            <div style={categoryStyle} onClick={() => handleCategoryClick('Profitability')}>
              Profitability
            </div>
            <div style={categoryStyle} onClick={() => handleCategoryClick('Technical Indicators')}>
              Technical Indicators
            </div>
            {/* Add more categories as needed */}
          </div>
          <div style={{ marginRight: '200px', flex: 2 }}>
            {renderContent()}
          </div>
        </div>
      </div>
    </>
  );
};

const StockScreener = () => {
  const isDropDownTrue = useSelector((state) => state.isDropdownOpen);
  document.title = 'Stock Screener - Fintuition';

  const titleRef = useRef(null);
  const exploreRef = useRef(null);
  const newBoxRef = useRef(null); // Ref for the new box
  const popupRef = useRef(null); // Ref for the popup

  useEffect(() => {
    anime.timeline({ easing: 'easeInOutQuad' })
      .add({
        targets: titleRef.current,
        translateY: ['0%', '20%'],
        scaleX: [0, 1],
        duration: 700,
      })
      .add({
        targets: exploreRef.current,
        translateY: ['-100%', '0%'],
        duration: 700,
      }, '-=700') // Overlap the animation with the title for smooth transition
      .add({
        targets: newBoxRef.current, // Animate the new box
        translateY: ['3%', '0%'], // Slight upward movement
        duration: 700,
      }, '-=400'); // Slightly overlap with the previous animation for a smooth transition
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage popup visibility
  const [filterPopupOpen, setFilterPopupOpen] = useState(false); // State to manage popup visibility
  const [isMyScreenerClicked, setIsMyScreenerClicked] = useState(true); // State to track My Screener button click
  const [isPlusButtonClicked, setIsPlusButtonClicked] = useState(false); // State to track '+' button click
  const [newBoxHeight, setNewBoxHeight] = useState('132%'); // Initial height
  const [overLayHeight, setOverLayHeight] = useState('2000px'); // Initial height
  const [showFilters, setShowFilters] = useState(false); // State to manage visibility of Filters component

  const handlePlusButtonClick = () => {
    setIsPopupOpen(true);
    setIsPlusButtonClicked(true); // Set the state to true when '+' button is clicked
    setIsMyScreenerClicked(false); // Reset the state for My Screener button
    anime({
      targets: popupRef.current,
      rotateX: [90, 0], // Rotate from 90 degrees (initially tilted) to 0 degrees (straightened up)
      duration: 1000,
      easing: 'easeInOutQuad',
      transformOrigin: '50% 0%', // Set the pivot point to the top center
    });
    // Disable scrolling when the popup is open
    document.body.style.overflow = 'hidden';
    console.log('Plus button clicked');
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setIsPlusButtonClicked(false);
    // Re-enable scrolling when the popup is closed
    document.body.style.overflow = 'auto';
  };

  const handleCloseFilterPopup = () => {
    document.body.style.overflow = 'auto';
    setFilterPopupOpen(false);
  };

  const handleMyScreenerClick = () => {
    setIsMyScreenerClicked(!isMyScreenerClicked); // Toggle the state for My Screener button
    setIsPlusButtonClicked(false); // Reset the state for '+' button
    console.log('My Screener button clicked');
  };

  const addFilter = () => {
    setFilterPopupOpen(true);
    // Find the index of the first tag in the first row
    const firstTagIndex = tags.findIndex((_, index) => index > 0 && index % 3 === 0);
    // If no tag exists, add the new filter at the end
    if (firstTagIndex === -1) {
      // setTags([...tags, 'New Filter']);
    } else {
      // Insert the new filter right below the first tag in the first row
      const newTags = [...tags.slice(0, firstTagIndex), 'New Filter', ...tags.slice(firstTagIndex)];
      setTags(newTags);
    }
    document.body.style.overflow = 'hidden';
    console.log('Add filter button clicked');
  };

  // State for tags
  const [tags, setTags] = useState([
    'Intrinsic value base case: Undervalued by 20%',
    'P/E value base case: P/E under 20',
    'P/E/G value base case: P/E/G under 1',
  ]);

  // Function to add a tag
  const addTag = (newTag) => {
    setTags([...tags, newTag]);
  };

  // Function to remove a tag
  const handleCloseTag = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const updateBoxHeight = (height) => {
    setNewBoxHeight(height);
    const parsedHeight = parseInt(overLayHeight, 10);

    // Add 1000px to the existing height and convert it back to a string with 'px' unit
    const newHeight = `${parsedHeight + 1000}px`;

    // Update the state with the new height
    setOverLayHeight(newHeight);
  };

  const stockScreenerStyles = {
    minHeight: overLayHeight,
    fontFamily: 'Manrope, sans-serif',
    color: 'white',
    height: '300%',
    background: 'linear-gradient(60deg, #0F1822 0%, #0F1822 10%, #00234B 48%, #00234B 52%, #0F1822 90%, #0F1822 100%)',
  };

  const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '300%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
    zIndex: 10, // Explicitly lower than the popup
  };

  const titleStyles = {
    fontSize: '42px',
    fontWeight: 'bold',
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(109.6deg, #8bfffb 11.2%, #2874ff 91.1%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    whiteSpace: 'nowrap',
  };

  const exploreStyles = {
    color: '#9aa5b1',
    fontFamily: 'Lato',
    textTransform: 'uppercase',
    fontSize: '22.5px',
    lineHeight: '28.9286px',
    top: 'calc(20% + 50px)',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'absolute',
  };

  // Styles for the new box
  const newBoxStyles = {
    backgroundColor: '#222A33',
    borderRadius: '12px',
    position: 'absolute',
    top: 'calc(20% + 120px)',
    height: newBoxHeight,
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: '78%',
    color: 'white',
    textAlign: 'center',
  };

  const horizontalLineStyle = {
    width: '100%',
    height: '1px',
    backgroundColor: '#9aa5b1',
    margin: '40px auto', // Adjust margin to position the line vertically
  };

  // Conditional styles for My Screener button
  const buttonStyles = {
    backgroundColor: isMyScreenerClicked ? '#475261' : 'transparent', // Change background color based on click state
    color: 'white',
    border: 'none',
    padding: '3px 10px', // Adjusted padding for the My Screener button
    fontFamily: 'Lato',
    fontSize: '17px',
    borderRadius: '5px',
    cursor: 'pointer',
    float: 'left',
    justifyContent: 'left',
    transition: 'background-color 0.3s ease',
    marginLeft: '1%',
    marginTop: '0.5%', // Adjust the margin-top value to move the button down
  };

  // Conditional styles for '+' button
  const plusButtonStyles = {
    backgroundColor: isPlusButtonClicked ? 'grey' : 'transparent', // Change background color based on click state
    color: 'white',
    border: 'none',
    padding: '5px 6px', // Smaller padding for the '+' button
    fontFamily: 'Lato',
    fontSize: '21px',
    cursor: 'pointer',
    marginLeft: '5%',
    transition: 'background-color 0.3s ease',
    float: 'left',
    marginTop: '0.2%',
  };

  const popupStyles = {
    backgroundColor: '#333',
    padding: '20px',
    borderRadius: '5px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '420px',
    height: '300px',
    zIndex: 11,
    display: isPopupOpen ? 'block' : 'none', // Control visibility based on state
  };

  const closeButtonStyles = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    color: 'white',
    fontSize: '24px',
  };

  const filterTextStyles = {
    color: 'white',
    border: 'none',
    padding: '3px 10px', // Adjusted padding for the My Screener button
    fontFamily: 'Lato',
    fontSize: '17px',
    borderRadius: '5px',
    float: 'left',
    marginTop: '-30px',
    marginLeft: '0.5%',
    fontWeight: '700',
    justifyContent: 'left',
    transition: 'background-color 0.3s ease',
  };

  const tagsContainerStyle = {
    display: 'flex', // Use flexbox for layout
    flexWrap: 'wrap', // Allow items to wrap to the next line
    alignItems: 'center', // Vertically center the items in the line
    gap: '10px', // Space between each tag and the button
    padding: '10px 0', // Vertical padding inside the container, no horizontal padding to maintain alignment
    marginLeft: '10px', // Ensure alignment with the "Filters" text
    marginTop: '-28px', // Move the row of tags up
    width: 'calc(100% - 20px)', // Adjust width to account for margins and prevent premature wrapping
  };

  const addFilterButton = {
    color: 'blue',
    border: 'none',
    fontFamily: 'Lato',
    borderRadius: '12px',
    padding: '5px 10px',
    marginRight: '5px',
    fontSize: '17px',
    cursor: 'pointer',
    height: '100%',
    backgroundColor: 'lightblue',
    fontWeight: '700',
    transition: 'background-color 0.3s ease',
    display: 'inline-flex',
    alignItems: 'center',
  };

  const filtersContainerStyle = {
    display: 'flex', // Use flexbox for layout
    alignItems: 'center', // Vertically center the items
    flexWrap: 'nowrap', // Prevent wrapping of items
  };

  return (
    <div style={stockScreenerStyles}>
      {isPopupOpen && <div style={overlayStyles} onClick={handleClosePopup}></div>}
      <Navbar />
      {isDropDownTrue && <ToolsDropDown />}
      <div ref={titleRef} style={titleStyles}>STOCK SCREENER</div>
      <div ref={exploreRef} style={exploreStyles}>EXPLORE INVESTMENT OPPORTUNITIES</div>
      <div ref={newBoxRef} style={newBoxStyles}>
        <button style={buttonStyles} onClick={handleMyScreenerClick}>Default Screener</button>
        <button style={plusButtonStyles} onClick={handlePlusButtonClick}>+</button>
        <div style={horizontalLineStyle}></div>
        <div style={filtersContainerStyle}>
          <div style={filterTextStyles}>Filters:</div>
          <div style={tagsContainerStyle}>
            {tags.map((tag, index) => (
              <Tag key={index} text={tag} onClose={() => handleCloseTag(index)} />
            ))}
            <button style={addFilterButton} onClick={addFilter}>Add Filter</button>
          </div>
        </div>
        <ScreenerResultsSection tags={tags} updateBoxHeight={updateBoxHeight} currentBoxHeight={newBoxHeight} />
      </div>
      <FiltersPopup isOpen={filterPopupOpen} onClose={handleCloseFilterPopup} />
      <div ref={popupRef} style={popupStyles}>
        <span style={closeButtonStyles} onClick={handleClosePopup}>X</span>
        This is a popup.
      </div>
    </div>
  );
};

const ScreenerResultsSection = ({ tags, updateBoxHeight, currentBoxHeight }) => {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const [screenerResults, setScreenerResults] = useState([]);
  const [countUpInstance, setCountUpInstance] = useState(null); // State to store CountUp instance
  const [isResultsFetched, setIsResultsFetched] = useState(false); // State to track if results have been fetched

  useEffect(() => {
    if (isResultsFetched) {
      // Start the anime.js animation
      const animation = anime({
        targets: 'svg',
        translateY: ['-10%', '38%'], // Adjust the values to move the icon down less
        opacity: [0.2, 1], // Adjust the opacity values as needed
        duration: 1000,
        easing: 'easeInOutQuad',
      });
    }
  }, [isResultsFetched]); // Run this effect when isResultsFetched changes

  useEffect(() => {
    const fetchScreenerResults = async () => {
      try {
        const response = await fetch('https://www.fintuition.org/api/screenerResults/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ tags }), // Include tags in the request body
        });
        if (response.ok) {
          const data = await response.json();
          console.log('Screener results:', data);
          setScreenerResults(data.results); // Update state with fetched results
          setIsResultsFetched(true); // Set the flag to indicate that results have been fetched
          // Create a CountUp instance
          const countUp = new CountUp('countup', 0, data.results.length, 0, 2, {
            duration: 2, // Animation duration in seconds
            separator: ',', // Separator for thousands
          });
          setCountUpInstance(countUp);
          // Start the CountUp animation
          countUp.start();
        } else {
          console.error('Failed to fetch screener results');
        }
      } catch (error) {
        console.error('Error fetching screener results:', error);
      }
    };

    // Delay the API call by 5 seconds
    const delay = setTimeout(() => {
      fetchScreenerResults();
    }, 5000);

    return () => {
      clearTimeout(delay);
    };
  }, [tags]); // Specify tags as a dependency for the useEffect

  const sectionStyles = {
    backgroundColor: '#30455E',
    padding: '20px',
    position: 'relative',
    width: '100%',
    height: '100%',
    margin: 0,
    borderTopLeftRadius: '0', // No border radius on the top-left corner
    borderTopRightRadius: '0', // No border radius on the top-right corner
    borderBottomLeftRadius: '20px', // Border radius on the bottom-left corner
    borderBottomRightRadius: '20px', // Border radius on the bottom-right corner    color: 'white',
    textAlign: 'center',
  };

  const titleStyles = {
    background: '-webkit-linear-gradient(45deg, #00c9ff, #8eff9a 80%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    float: 'left',
    fontFamily: 'Lato',
    fontSize: '34px',
    paddingLeft: '40px',
    marginTop: '-100px', // Adjust the margin-top value to move the title up
  };

  const screenerLengthStyles = {
    color: 'grey',
    float: 'left',
    fontFamily: 'Lato',
    fontSize: '20px',
    paddingLeft: '40px',
    top: '60px',
    position: 'absolute',
  };

  const iconStyles = {
    fontSize: '44px',
    position: 'absolute',
    left: '10px', // Adjust the value as needed to move the icon to the right
    transform: 'translateY(-50%)', // Center the icon vertically
    color: 'grey',
    top: '10px',
  };

  const screeningTextStyles = {
    color: 'grey',
    float: 'left',
    fontFamily: 'Lato',
    fontSize: '30px',
    paddingLeft: '40px',
    top: '44%', // Center vertically
    position: 'absolute',
    right: '42%', // Center horizontally
  };

  const animationStyles = {
    width: '20%', // Adjust as needed
    height: '20%', // Adjust as needed
    justifyContent: 'center', // This property may not be needed for absolute positioning
    right: '50%', // Center horizontally
    top: '50%', // Center vertically
    transform: 'translate(50%, -50%)', // Center the element precisely
    fontSize: '2vw', // Adjust font size based on viewport width
    position: 'absolute',
  };

  return (
    <div style={sectionStyles}>
      {isResultsFetched ? (
        <div id="countup">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style={iconStyles} className="clipboard-icon">
            <path fill="currentColor" d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-7 0a1 1 0 0 1 1 1a1 1 0 0 1-1 1a1 1 0 0 1-1-1a1 1 0 0 1 1-1M7 7h10V5h2v14H5V5h2z" />
          </svg>
          <span style={titleStyles}>Screener Results</span>
          <div style={screenerLengthStyles}>
            {screenerResults.length} stocks found
          </div>
          <ScreenedCompaniesGrid companies={screenerResults} updateBoxHeight={updateBoxHeight} currentBoxHeight={currentBoxHeight} />
        </div>
      ) : (
        <div>
          <ScreenerLoadingAnimation style={animationStyles} />
          <span style={screeningTextStyles}>Screening...</span>
          <ScreenerAnimation />
        </div>
      )}
    </div>
  );
};

const ScreenedCompaniesGrid = ({ companies, updateBoxHeight }) => {
  const [visibleCompanies, setVisibleCompanies] = useState(6); // Initially show 6 companies
  const navigate = useNavigate();

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)', // Two columns with equal width
    gap: '20px',
    padding: '5px',
    marginTop: '100px', // Adjust the margin-top value to move the grid down
  };

  const companyCardStyle = {
    maxWidth: '640px', // Max width to prevent cards from becoming too large
    width: '100%', // Makes the width of each card responsive to the container's width
    height: '300px',
    backgroundColor: '#293A51',
    borderRadius: '20px',
    padding: '5px',
    color: '#fff',
    cursor: 'pointer',
    transition: 'transform 0.3s', // Add transition for smooth effect
    boxSizing: 'border-box', // Ensure padding is included in the width calculation
    position: 'relative', // Position relative for absolute positioning of logo
  };

  // Updated button style to allow it to naturally follow the grid
  const loadMoreButtonStyle = {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'block', // Use block display
    margin: '20px auto', // Center the button horizontally, with margin above and below
  };
  const horizontalLineStyle = {
    width: '96%', // Reduce width to less than full width of the card
    height: '1px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    position: 'absolute',
    top: 'calc(14% + 33px)', // Position it right under the symbol
    left: '3%', // Start 5% from the left edge
    right: '3%', // Ensure the right end is also 5% from the edge
  };

  const descriptionStyle = {
    position: 'absolute',
    display: 'block',
    top: 'calc(14% + 40px)', // Adjust to ensure alignment with the horizontal line
    left: '1.5%', // Match horizontal line start
    padding: '0 10px', // Padding on both sides
    fontSize: '1em', // Font size adjustment
    color: '#fff', // Text color
    width: '94%', // Ensuring it spans the same as the horizontal line
    textAlign: 'left', // Aligns text to the left
  };

  const logoStyle = {
    position: 'absolute',
    width: '50px', // Adjust as needed
    height: '50px', // Adjust as needed
    left: '2%',
    display: 'flex',
    top: '4%', // Adjust as needed
    borderRadius: '50%', // Make it a circle
    overflow: 'hidden', // Ensure the image stays within the circle
  };

  const companyTickerStyle = {
    position: 'absolute',
    color: 'grey',
    top: '14%', // Adjust as needed
    left: '10%',
    display: 'flex',
    padding: '0 10px', // Adjust padding as needed
    fontSize: '1em', // Adjust font size as needed
  };

  const companyNameStyle = {
    position: 'absolute',
    left: '10%',
    display: 'flex',
    top: '4%', // Adjust as needed
    padding: '0 10px', // Adjust padding as needed
    fontSize: '1.5em', // Adjust font size as needed
  };

  const loadMore = () => {
    const newVisibleCompanies = visibleCompanies + 6; // Load 6 more companies
    setVisibleCompanies(newVisibleCompanies);

    // Assuming a fixed height for each company card and a known gap, calculate the new dynamic height
    const rows = Math.ceil(newVisibleCompanies / 2); // Assuming 2 items per row
    const totalHeight = rows * (300 + 20) + 175; // Total height calculation based on content
    updateBoxHeight(`${totalHeight}px`); // Update the height dynamically
  };

  const handleCompanyClick = (symbol) => {
    // Example: navigate to a dynamic URL or perform other actions
    navigate(`/dcf/${symbol}`);
  };

  return (
    <>
      <div style={gridContainerStyle}>
        {companies.slice(0, visibleCompanies).map((company, index) => (
          <div
            key={index}
            style={companyCardStyle}
            onMouseEnter={(e) => { e.currentTarget.style.transform = 'scale(1.05)'; }}
            onMouseLeave={(e) => { e.currentTarget.style.transform = 'scale(1)'; }}
            onClick={() => handleCompanyClick(company.symbol)} // Use onClick event for navigation
          >
            {/* Display company logo in a circle in the upper left corner */}
            <div style={logoStyle}>
              <img src={company.companyLogo} alt="Company Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
            <p style={companyNameStyle}>{company.companyName}</p>
            <p style={companyTickerStyle}>{company.symbol}</p>
            <div style={horizontalLineStyle}></div>
            <p style={descriptionStyle}>{company.description}</p>
          </div>
        ))}
      </div>
      {visibleCompanies < companies.length && (
        <button onClick={loadMore} style={loadMoreButtonStyle}>
          Load More
        </button>
      )}
    </>
  );
};

const TradeFinder = () => {
  const isDropDownTrue = useSelector((state) => state.isDropdownOpen);
  document.title = 'Stock Screener - Fintuition';

  const titleRef = useRef(null);
  const exploreRef = useRef(null);
  const newBoxRef = useRef(null); // Ref for the new box

  useEffect(() => {
    anime.timeline({ easing: 'easeInOutQuad' })
      .add({
        targets: titleRef.current,
        translateY: ['0%', '20%'],
        scaleX: [0, 1],
        duration: 700,
      })
      .add({
        targets: exploreRef.current,
        translateY: ['-100%', '0%'],
        duration: 700,
      }, '-=700') // Overlap the animation with the title for smooth transition
      .add({
        targets: newBoxRef.current, // Animate the new box
        translateY: ['3%', '0%'], // Slight upward movement
        duration: 700,
      }, '-=400'); // Slightly overlap with the previous animation for a smooth transition
  }, []);

  const [buttonClicked, setButtonClicked] = useState(true); // State to manage button click

  const handleButtonClick = () => {
    setButtonClicked(!buttonClicked);
  };

  const stockScreenerStyles = {
    minHeight: '2000px',
    fontFamily: 'Manrope, sans-serif',
    color: 'white',
    height: '100vh',
    background: 'linear-gradient(60deg, #0F1822 0%, #0F1822 10%, #00234B 48%, #00234B 52%, #0F1822 90%, #0F1822 100%)',
  };

  const titleStyles = {
    fontSize: '42px',
    fontWeight: 'bold',
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(109.6deg, #8bfffb 11.2%, #2874ff 91.1%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    whiteSpace: 'nowrap',
  };

  const exploreStyles = {
    color: '#9aa5b1',
    fontFamily: 'Lato',
    textTransform: 'uppercase',
    fontSize: '22.5px',
    lineHeight: '28.9286px',
    top: 'calc(20% + 50px)',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'absolute',
  };

  // Styles for the new box
  const newBoxStyles = {
    backgroundColor: '#222A33',
    padding: '20px',
    borderRadius: '5px',
    position: 'absolute',
    top: 'calc(20% + 120px)',
    height: '1000px',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: '80%',
    color: 'white',
    textAlign: 'center',
  };

  const horizontalLineStyle = {
    width: '100%',
    height: '1px',
    backgroundColor: '#9aa5b1',
    margin: '30px auto', // Adjust margin to position the line vertically
  };

  const buttonStyles = {
    backgroundColor: buttonClicked ? '#ccc' : 'transparent', // Change background color based on click state
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    fontFamily: 'Lato',
    fontSize: '18px',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    marginRight: '20px', // Move the button to the left
    position: 'absolute',
    top: '0',
    left: '0',
  };

  return (
    <div style={stockScreenerStyles}>
      <Navbar />
      {isDropDownTrue && <ToolsDropDown />}
      <div ref={titleRef} style={titleStyles}>TRADE FINDER</div>
      <div ref={exploreRef} style={exploreStyles}>EXPLORE INVESTMENT OPPORTUNITIES</div>
      <div ref={newBoxRef} style={newBoxStyles}>
        <button style={buttonStyles} onClick={handleButtonClick}>My Screener</button>
        <div style={horizontalLineStyle}></div>
      </div>
    </div>
  );
};

const PricePage = () => {
  const isDropDownTrue = useSelector((state) => state.isDropdownOpen);
  document.title = 'Pricing - Fintuition';

  const titleRef = useRef(null);
  const exploreRef = useRef(null);

  useEffect(() => {
    anime.timeline({
      easing: 'easeInOutQuad', // Easing function for smooth transition
    }).add({
      targets: titleRef.current,
      translateY: ['0%', '20%'], // Move the title down by 20%
      scaleX: [0, 1], // Start from scaleX 0 (collapsed) to 1 (expanded)
      duration: 700, // Duration of the animation in milliseconds
    }).add({
      targets: exploreRef.current,
      translateY: ['-100%', '0%'], // Move the explore text down by 100%
      duration: 700, // Duration of the animation in milliseconds
    }, 0); // Start the explore animation at the same time as the title animation
  }, []);

  const stockScreenerStyles = {
    minHeight: '100vh',
    fontFamily: 'Manrope, sans-serif',
    color: 'white',
    height: '100vh',
    background: 'linear-gradient(60deg, #0F1822 0%, #0F1822 10%, #00234B 48%, #00234B 52%, #0F1822 90%, #0F1822 100%)', // Static background gradient
  };

  const titleStyles = {
    fontSize: '42px',
    fontWeight: 'bold',
    position: 'absolute',
    top: '20%',
    left: '50%',
    fontFamily: 'Lato',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(109.6deg, #8bfffb 11.2%, #2874ff 91.1%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    backgroundImage: 'linear-gradient(109.6deg, rgb(139, 255, 251) 11.2%, rgb(40, 116, 255) 91.1%)',
    whiteSpace: 'nowrap',
  };

  const exploreStyles = {
    color: '#9aa5b1',
    fontFamily: 'Lato',
    textTransform: 'uppercase', // Convert text to all caps
    fontSize: '22.5px',
    lineHeight: '28.9286px',
    top: 'calc(20% + 50px)', // Adjusted position to be under the title
    left: '50%', // Center horizontally
    transform: 'translateX(-50%)', // Center horizontally
    position: 'absolute',
  };

  return (
    <div style={stockScreenerStyles}>
      <Navbar />
      {isDropDownTrue && <ToolsDropDown />}
      <div ref={titleRef} style={titleStyles}>Simple, affordable pricing</div>
    </div>
  );
};

const AboutUs = () => {
  document.title = 'About Us - Fintuition';
  const [animationFinished, setAnimationFinished] = useState(false);
  const isDropDownTrue = useSelector((state) => state.isDropdownOpen); // Get the value of isDropdownOpen from the Redux store
  const dispatch = useDispatch(); // Get the dispatch function from React-Redux
  useEffect(() => {
    // Set animationFinished to true after the animation duration
    const timeoutId = setTimeout(() => {
      setAnimationFinished(true);
    }, 1); // Adjust the duration as needed (in milliseconds)

    // Clear the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []);
  const aboutUsStyles = {
    background: `
    radial-gradient(
      circle at 3% 25%, /* Positioning the center of the gradient */
      rgba(0, 20, 40, 1) 0%, /* Even darker blue at the center */
      rgba(2, 58, 93, 1) 100% /* Transition to a darker blue towards the edges */
      `,
    minHeight: '100vh', // Ensure the gradient covers the entire viewport
    fontFamily: 'Manrope, sans-serif',
    color: 'var(--color-text)',
    height: '100vh',
  };

  const titleStyles = {
    fontSize: '3rem',
    fontWeight: 'bold',
    position: 'absolute',
    top: '50%',
    left: animationFinished ? '10%' : '-100%', // Start off-screen to the left
    transform: 'translateY(-50%)',
    opacity: animationFinished ? 1 : 0,
    transition: 'opacity 1s ease-in-out, left 1s ease-in-out', // Add transition for left property
    marginBottom: '10px',
    background: 'var(--gradient-text)',
    WebkitBackgroundClip: 'text',
    color: '#65DAF0',
    whiteSpace: 'nowrap', // Prevent line break for the animation
  };

  const subtitleStyles = {
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '-10px',
  };

  return (
    <div style={aboutUsStyles}>
      <Navbar />
      {isDropDownTrue && <ToolsDropDown />}
      <AboutUsHeader />
      <AboutUsBody />
    </div>
  );
};

const ProfilePage = () => {
  document.title = 'Profile Page - Fintuition';
  const [selectedFile, setSelectedFile] = useState(null);
  const tickersEntered = useSelector((state) => state.tickersEntered);
  const allTickers = removeDuplicates(tickersEntered);
  const isDropDownTrue = useSelector((state) => state.isDropdownOpen); // Get the value of isDropdownOpen from the Redux store
  const dispatch = useDispatch(); // Get the dispatch function from React-Redux
  // console.log('TICKERS ENTERED:', allTickers);
  const email = useSelector((state) => state.user);
  const [newFullName, setNewFullName] = useState('');

  // Handler for updating the full name
  const handleUpdateFullName = () => {
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    if (file) {
      const formData = new FormData();
      formData.append('profile_picture', file);
      formData.append('email', email); // Include the email in the FormData

      try {
        const response = await fetch('https://www.fintuition.org/api/profilePicture/', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          // Handle success
          console.log('Profile picture uploaded successfully.');
        } else {
          // Handle error
          console.error('Profile picture upload failed.');
        }
      } catch (error) {
        console.error('An error occurred while uploading the profile picture:', error);
      }
    }
  };

  // Define styles as components
  const profilePageStyles = {
    profileInfoLeft: {
      background: 'linear-gradient(rgba(0, 0, 0, 60%), rgba(0, 0, 0, 60%)) padding-box, linear-gradient(to right, rgba(44, 176, 237, 0.2), rgba(28, 212, 212, 0.2)) border-box',
      borderRadius: '8px',
      padding: '20px',
      minWidth: '500px', // Half the width of the current box
      height: '300px',
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
      marginTop: '100px',
      marginLeft: '-250px', // Move the left box to the left
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '10px', // Adjust as needed
    },
    personIcon: {
      fontSize: '20px',
      marginRight: '10px', // Add some spacing between the icon and the input
    },
    input: {
      width: '70%', // Adjust the width of the input field
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      fontSize: '16px',
    },
    updateButton: {
      backgroundColor: '#007bff',
      color: 'white',
      padding: '10px 20px',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '16px',
    },
    circularPlaceholder: {
      width: '150px', // Adjust the size of the circular placeholder
      height: '150px', // Adjust the size of the circular placeholder
      borderRadius: '50%', // Makes it circular
      background: 'white', // Background color for the placeholder
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
      marginTop: '-100px',
      right: '-145px',
    },
    cameraIcon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '24px', // Adjust the size of the camera icon
      cursor: 'pointer',
      marginTop: '60px',
    },
    profilePage: {
      fontFamily: 'Lato, sans-serif',
      background: `
        radial-gradient(
          circle at 3% 25%,
          rgba(0, 40, 83, 1) 0%,
          rgba(4, 12, 24, 1) 25%
        )`,
      minHeight: '100vh', // Ensure the gradient covers the entire viewport
      minWidth: '1000px',
      overflowX: 'hidden', // Prevent horizontal scrolling
    },
    horizontalLine: {
      borderTop: '1px solid grey', // Use borderTop to create the horizontal line
      width: '965px', // Set the width to span the entire container
      position: 'absolute', // Position the line absolutely within the text container
      transform: 'none', // Adjust the position to center the line vertically
      marginTop: '-8px',
    },
    horizontalLine2nd: {
      borderTop: '1px solid grey', // Use borderTop to create the horizontal line
      width: '965px', // Set the width to span the entire container
      transform: 'none', // Adjust the position to center the line vertically
    },
    horizontalLine3rd: {
      borderTop: '1px solid grey', // Use borderTop to create the horizontal line
      width: '965px', // Set the width to span the entire container
      transform: 'none', // Adjust the position to center the line vertically
    },
    profileContent: {
      width: '1000px',
      margin: '0 auto',
      minWidth: '1000px',
      padding: '20px',
    },
    profileInfo: {
      background: 'linear-gradient(rgba(0, 0, 0, 60%), rgba(0, 0, 0, 60%)) padding-box, linear-gradient(to right, rgba(44, 176, 237, 0.2), rgba(28, 212, 212, 0.2)) border-box',
      borderRadius: '8px',
      padding: '20px',
      minWidth: '1000px',
      height: '300px',
      marginTop: '100px',
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
      marginLeft: '20px', // Move the current box to the right to make room for the left box
    },
    email: {
      fontSize: '18px',
      marginBottom: '10px',
      fontFamily: 'Lato',
      color: 'white',
      minWidth: '1000px',
    },
    fullName: {
      fontSize: '15px',
      marginTop: '40px', // Adjust this value to move the text lower
      fontFamily: 'Lato',
      color: 'white',
      minWidth: '1000px',
    },
    loginCredentials: {
      fontSize: '15px',
      marginTop: '35px', // Adjust this value to move the text lower
      fontFamily: 'Lato',
      color: 'white',
      minWidth: '1000px',
    },
    mailIcon: {
      fontSize: '20px', // You can adjust the font size as needed
      marginRight: '10px', // Add some spacing between the icon and the text
    },
    emailText: {
      color: 'white',
    },
    resetPassword: {
      fontSize: '18px',
      fontFamily: 'Lato',
      color: 'white',
      minWidth: '1000px',
      marginTop: '35px', // Adjust this value to move the text lower
    },
  };

  return (
    <div style={profilePageStyles.profilePage}>
      {/* Include the existing Navbar component */}
      <Navbar />
      {isDropDownTrue && <ToolsDropDown />}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', marginTop: '-100px' }}>
        <div style={profilePageStyles.profileContent}>
          <div style={{ display: 'flex' }}>
            {/* Add the left box */}
            <div style={profilePageStyles.profileInfoLeft}>
              <label
                htmlFor="profilePictureInput"
                style={{
                  ...profilePageStyles.circularPlaceholder,
                  backgroundColor: 'blue',
                  cursor: 'pointer',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                {selectedFile ? (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Selected Profile"
                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
                  />
                ) : (
                  <>
                    <IoMdPerson style={{ fontSize: '80px', color: 'white' }} />
                    <div style={{ fontSize: '16px', color: 'white', marginTop: '5px' }}></div>
                  </>
                )}
              </label>
              <input
                type="file"
                id="profilePictureInput"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
              />

            </div>
            {/* Current box */}
            <div style={profilePageStyles.profileInfo}>
              <h1 style={profilePageStyles.email}>Account Details</h1>
              <div style={profilePageStyles.horizontalLine} />
              <h1 style={profilePageStyles.fullName}>Full Name</h1>
              <div style={profilePageStyles.horizontalLine2nd} />
              <div style={profilePageStyles.inputContainer}>
                <span style={profilePageStyles.personIcon}>👤</span>
                <input
                  type="text"
                  placeholder="Enter new full name"
                  style={profilePageStyles.input}
                  value={newFullName}
                  onChange={(e) => setNewFullName(e.target.value)}
                />
                <button
                  style={profilePageStyles.updateButton}
                  onClick={handleUpdateFullName}
                >
                  Update
                </button>
              </div>
              <h1 style={profilePageStyles.loginCredentials}>Login Credentials:</h1>
              <div style={profilePageStyles.horizontalLine3rd} />
              <div style={profilePageStyles.inputContainer}>
                <IoMdMail style={{ ...profilePageStyles.mailIcon, color: 'white' }} /> {/* Set color to white */}
                <span style={profilePageStyles.emailText}>{email}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DCFTitle = () => {
  const DCFTitleStyles = {
    centeredContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '-360px',
      height: '100vh',
      overflow: 'hidden', // Hide content that overflows the container
    },
    centeredText: {
      transform: 'translateX(-50%)', // Increased initial distance
      transition: 'transform 0.5s ease-in-out', // Apply a smooth transition
      textAlign: 'center',
      fontSize: '32px',
      fontWeight: 'bold',
      color: 'white',
      fontFamily: "'Lato', sans-serif",
    },
    greyNumber: {
      color: 'grey',
      marginRight: '8px',
    },
  };

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Add a short delay to start the animation
    const animationTimeout = setTimeout(() => {
      setAnimate(true);
    }, 100);

    return () => clearTimeout(animationTimeout);
  }, []);

  if (animate) {
    // Apply the slide-in animation by changing the transform value
    DCFTitleStyles.centeredText.transform = 'translateX(0)';
  }

  return (
    <div style={DCFTitleStyles.centeredContainer}>
      <p style={DCFTitleStyles.centeredText}>
        <span style={DCFTitleStyles.greyNumber}>1.</span>&nbsp; DCF VALUE
      </p>
    </div>
  );
};

const ModelSettingsTitle = () => {
  const dispatch = useDispatch();
  const tickerData = useSelector((state) => state.tickerData);
  const ticker = tickerData?.symbol;
  const ModelSettingsTitleStyles = {
    centeredContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      marginTop: '-300px', // Add a negative margin to move the content higher
    },
    centeredText: {
      textAlign: 'center',
      fontSize: '32px',
      fontWeight: 'bold',
      color: 'white',
      fontFamily: "'Lato', sans-serif", // Replace 'Your Font' with the font name from the Google Font URL
    },
    greyNumber: {
      color: 'grey', // Set the color of the number to grey
      marginRight: '8px', // Add more space between the "1." and "DCF Value"
    },
  };

  return (
    <div style={ModelSettingsTitleStyles.centeredContainer}>
      <p style={ModelSettingsTitleStyles.centeredText}>
        <span style={ModelSettingsTitleStyles.greyNumber}>2.</span>&nbsp; MODEL SETTINGS
      </p>
    </div>
  );
};

const IntrinsicValueHistoryTitle = () => {
  const IntrinsicValueHistoryTitleStyles = {
    centeredContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      minWidth: '1600px',
      borderRadius: '20px',
      marginLeft: '3%',
      marginRight: '10%',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      padding: '20px',
      marginTop: '-350px', // Add a negative margin to move the content higher
    },
    centeredText: {
      textAlign: 'center',
      fontSize: '32px',
      fontWeight: 'bold',
      color: 'white',
      fontFamily: "'Lato', sans-serif", // Replace 'Your Font' with the font name from the Google Font URL
    },
    greyNumber: {
      color: 'grey', // Set the color of the number to grey
      marginRight: '8px', // Add more space between the "1." and "DCF Value"
    },
  };

  return (
    <div style={IntrinsicValueHistoryTitleStyles.centeredContainer}>
      <p style={IntrinsicValueHistoryTitleStyles.centeredText}>
        <span style={IntrinsicValueHistoryTitleStyles.greyNumber}>3.</span>&nbsp; INTRINSIC VALUE HISTORY
      </p>
    </div>
  );
};

const CompetitorCard = () => {
  const tickerData = useSelector((state) => state.tickerData);
  const tickers = tickerData?.competitors; // Replace this with your array of tickers
  const tickerNames = tickerData?.competitorsNames;
  const wordToReplace = 'logo';
  const replacementWord = '';
  const tickerImgs = tickerData?.competitorsImgUrls;
  const competitorsNames = tickerNames.map((str) =>
    // Use the replace() method with a regular expression and the 'g' flag
    // to replace all occurrences of the wordToReplace with the replacementWord
    str.replace(new RegExp(wordToReplace, 'gi'), replacementWord));
  let spacing = '0%';
  if (tickers.length === 3) {
    spacing = '5%';
  }
  if (tickers.length > 4) {
    while (tickers.length > 4) {
      tickers.pop();
    }
  }
  const CompetitorCardStyles = {
    cardContainer: {
      backgroundColor: '#f0f0f0',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      height: '100px', // Adjust the height as needed
      width: '330px', // Adjust the width as needed
      borderRadius: '10px',
      top: '50px',
      position: 'relative',
      marginRight: '20px', // Add spacing between the cards
      display: 'inline-block', // Stack the cards horizontally
      transition: 'transform 0.2s ease-in-out',
      margin: '1%',
      marginLeft: spacing,
      left: '8%',
      verticalAlign: 'top', // Align cards from the top
    },
    competitorCardContainer: {
      display: 'flex',
      flexDirection: 'column', // Stack the items vertically
      alignItems: 'flex-start', // Align items to the left
      justifyContent: 'center', // Align items vertically at the center
      height: '100%',
      marginTop: '-25px', // Move the ticker name up more (adjust the value as needed)
      padding: '10px', // Add padding to create space between ticker and name
      verticalAlign: 'top', // Align cards from the top
    },
    companyName: {
      fontSize: '16px', // Adjust the font size for the company name
      verticalAlign: 'top', // Align cards from the top
      marginTop: '1px',
      fontFamily: "'Lato'",
    },
    imageContainer: {
      position: 'absolute',
      top: '10px', // Adjust the top position as needed
      right: '10px', // Adjust the right position as needed
    },
    companyTicker: {
      fontSize: '20px', // Adjust the font size for the company name
      verticalAlign: 'top', // Align cards from the top
      marginTop: '1px',
      fontWeight: 'bold',
      fontFamily: "'Lato'",
    },
    // New style for the image itself
    image: {
      verticalAlign: 'top', // Align cards from the top
      width: '50px', // Set the desired width for the image
      height: '50px', // Set the desired height for the image
    },
    // Add other styles as needed
  };
  return (
    <div>
      {tickers.map((ticker, index) => (
        <a key={index} href={`/dcf/${ticker}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <div style={CompetitorCardStyles.cardContainer} className="enlarge-on-hover">
            <div style={CompetitorCardStyles.competitorCardContainer}>
              {/* Render your ticker data or content here */}
              <p style={CompetitorCardStyles.companyTicker}>{ticker}</p>
              <p style={CompetitorCardStyles.companyName}>{competitorsNames[index]}</p>
            </div>
            <div style={CompetitorCardStyles.imageContainer}>
              {/* Add your image source here */}
              <img src={tickerImgs[index]} style={CompetitorCardStyles.image} />
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

const InfoCard = () => {
  const dispatch = useDispatch();
  const tickerData = useSelector((state) => state.tickerData);
  const ticker = tickerData?.symbol;
  // let dateTime = '';
  // let options = {};
  // let formattedDate = '';
  // if (tickerData) {
  //   dateTime = new Date(tickerData.updated_at);
  //   options = { year: 'numeric', month: 'long', day: 'numeric' };
  //   formattedDate = dateTime.toLocaleDateString('en-US', options);
  // }
  const [intrinsicValue, setIntrinsicValue] = useState(tickerData?.fairValue);
  const [whichCase, setWhichCase] = useState('Base Case');
  const dRate = tickerData?.WACC;
  let discountRate = dRate * 100;
  discountRate = discountRate.toFixed(2);
  const [wacc, setWACC] = useState(tickerData.WACC);
  const [years, setYears] = useState(5);
  const DiscountRateControl = () => {
    // Function to simulate the API call to send the new WACC to the backend
    const sendWACCToBackend = async (newWACC) => {
      try {
        // Replace this with your actual API call using Axios or Fetch
        const response = await fetch('https://www.fintuition.org/api/customWACC/dcf/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ticker: tickerData.symbol,
            newWACC,
          }),
        });

        // Handle the response if needed
        const data = await response.json();
        // console.log('API Response:', data);
        setIntrinsicValue(data.fairValue);
      } catch (error) {
        console.error('Error sending WACC to backend:', error);
      }
    };

    const sendYearsToBackend = async (newYears) => {
      try {
        // Replace this with your actual API call using Axios or Fetch
        const response = await fetch('https://www.fintuition.org/api/customYears/dcf/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ticker: tickerData.symbol,
            newYears,
          }),
        });

        // Handle the response if needed
        const data = await response.json();
        console.log('Year change API Response:', data);
      } catch (error) {
        console.error('Error sending WACC to backend:', error);
      }
    };

    const increaseWACC = {
      fontSize: '24px', // Adjust the font size to make the buttons larger
      padding: '10px 20px', // Add padding to increase the button size
      position: 'absolute',
      top: '80%',
      zIndex: 2,
    };

    const increaseYears = {
      fontSize: '24px', // Adjust the font size to make the buttons larger
      padding: '10px 20px', // Add padding to increase the button size
      position: 'absolute',
      top: '80%',
      left: '16.5%',
      zIndex: 2,
    };

    const decreaseYears = {
      fontSize: '24px', // Adjust the font size to make the buttons larger
      padding: '10px 20px', // Add padding to increase the button size
      position: 'absolute',
      top: '80%',
      left: '20%',
      zIndex: 2,
    };

    const decreaseWACC = {
      fontSize: '24px', // Adjust the font size to make the buttons larger
      padding: '10px 20px', // Add padding to increase the button size
      position: 'absolute',
      top: '80%',
      left: '4.5%',
      zIndex: 2,
    };

    const handleIncreaseYears = () => {
      const incYears = years + 1;
      setYears(incYears);
      sendYearsToBackend(incYears);
    };

    const handleIncreaseWACC = () => {
      const newWACC = wacc * 100;
      let increasedWacc = newWACC + 1;
      increasedWacc /= 100;
      setWACC(increasedWacc);
      sendWACCToBackend(increasedWacc);
    };

    const handleDecreaseWACC = () => {
      const newWACC = wacc * 100;
      let decreasedWacc = newWACC - 1;
      decreasedWacc /= 100;
      setWACC(decreasedWacc);
      sendWACCToBackend(decreasedWacc);
    };

    return (
      <div>
        <button style={increaseWACC} onClick={handleIncreaseWACC}>+</button>
        <button style={decreaseWACC} onClick={handleDecreaseWACC}>-</button>
        <button style={increaseYears} onClick={handleIncreaseYears}>+</button>
        <button style={decreaseYears} onClick={handleDecreaseWACC}>-</button>
      </div>
    );
  };
  const handleBestCaseClick = () => {
    // Update the intrinsicValue state with the "fairValueBest" value from tickerData
    setIntrinsicValue(tickerData?.fairValueBest);
    dispatch(setCase('Best Case')); // Dispatch the setCase action with the new value
    setWhichCase('Best Case');
  };
  const handleBaseCaseClick = () => {
    // Update the intrinsicValue state with the "fairValueBest" value from tickerData
    setIntrinsicValue(tickerData?.fairValue);
    dispatch(setCase('Base Case')); // Dispatch the setCase action with the new value
    setWhichCase('Base Case');
  };
  useEffect(() => {
    setIntrinsicValue(tickerData?.fairValue);
  }, [tickerData]);
  const IntrinsicValueChart = () => {
    // Data for the bar chart (You can replace this with your actual data)
    const stockPriceStr = tickerData?.stockPrice.replace(/[^\d.-]/g, '');
    const stockPriceNbr = Number(stockPriceStr);
    const data = [intrinsicValue]; // Use the intrinsicValue state here
    const stockPriceData = [stockPriceNbr]; // The value you want to represent (Stock Price)
    const chartOptions = {
      chart: {
        type: 'bar',
        height: 120, // Adjust the height of the chart
        width: 530, // Adjust the width of the chart
      },
      title: {
        text: '', // Empty title to remove the default Highcharts title
      },
      xAxis: {
        categories: ['Intrinsic Value', 'Stock Price'], // Label for the bar
        visible: false, // Hide the X-axis labels
      },
      yAxis: {
        visible: false, // Hide the Y-axis labels
      },
      legend: {
        enabled: false, // Hide the legend
      },
      tooltip: {
        formatter() {
          // Customize the tooltip format for each series separately
          return this.series.name === 'Stock Price'
            ? `<span style="color:${this.point.color}">\u25CF</span> ${this.series.name}: <b>${this.point.y}</b><br/>`
            : `<span style="color:${this.point.color}">\u25CF</span> ${this.series.name}: <b>${this.point.y}</b>`;
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            align: 'left', // Align the data labels to the left
            enabled: true,
            inside: true,
            style: {
              fontWeight: 'bold',
              color: 'white',
              fontFamily: "'Lato'",
            },
            formatter() {
              return this.series.name; // Display the label on the bar
            },
          },
          borderRadius: 10, // Set the corner radius for the bars (adjust this value as needed)
          groupPadding: 0.05, // Adjust the groupPadding (smaller value means less gap between groups)
        },
      },
      series: [
        {
          name: 'Intrinsic Value',
          data,
          color: '#2E5984', // Set the color for the first bar (Intrinsic Value)
        },
        {
          name: 'Stock Price',
          data: stockPriceData,
          color: '#A9A9A9', // Set the color for the second bar (Stock Price) - Grey
        },
      ],
    };
    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  };

  const InfoCardStyles = { // InfoCardStyles
    fairValueText: {
      fontSize: '36.4px',
      fontWeight: 'bold',
      color: '#1991d2',
      marginBottom: '30px',
    },
    discountRateTitle: {
      fontFamily: "'Lato', sans-serif",
      fontSize: '16px', // Change the font size
      color: '#3f4d5a', // Change the text color to blue
      fontWeight: 'bolder',
      position: 'absolute',
      top: '67%',
      right: '91%',
    },
    forecastPeriodTitle: {
      fontFamily: "'Lato', sans-serif",
      fontSize: '16px', // Change the font size
      color: '#3f4d5a', // Change the text color to blue
      fontWeight: 'bolder',
      position: 'absolute',
      top: '67%',
      verticalAlign: 'top',
      right: '75%',
    },
    discountRateText: {
      fontFamily: "'Lato', sans-serif",
      fontSize: '19.99px', // Change the font size
      color: '#3f4d5a', // Change the text color to blue
      fontWeight: 'bolder',
      position: 'absolute',
      padding: '1%',
      minHeight: '5px',
      left: '2.2%',
      top: '68%',
      zIndex: '2',
    },
    forecastPeriodText: {
      fontFamily: "'Lato', sans-serif",
      fontSize: '19.99px', // Change the font size
      color: '#3f4d5a', // Change the text color to blue
      fontWeight: 'bolder',
      position: 'absolute',
      padding: '1%',
      minHeight: '5px',
      left: '17.1%',
      top: '68%',
      zIndex: '2',
    },
    bestCaseButton: {
      backgroundColor: '#4CAF50', // Green background color
      color: 'white', // White text color
      padding: '10px 20px', // Padding around the text
      border: 'none', // No border
      borderRadius: '4px', // Rounded corners
      cursor: 'pointer', // Cursor changes to a pointer on hover
      marginTop: '10px', // Adjust the top margin to control the distance from the chart
      right: '50%',
      bottom: '-50%',
      position: 'absolute',
    },
    baseCaseButton: {
      backgroundColor: '#4CAF50', // Green background color
      color: 'white', // White text color
      padding: '10px 20px', // Padding around the text
      border: 'none', // No border
      borderRadius: '4px', // Rounded corners
      cursor: 'pointer', // Cursor changes to a pointer on hover
      marginTop: '10px', // Adjust the top margin to control the distance from the chart
      right: '35%',
      bottom: '-50%',
      position: 'absolute',
    },
    cardContainer: {
      backgroundColor: 'white',
      padding: '20px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      margin: '10px',
      marginTop: '-400px',
      marginLeft: '3%',
      marginRight: '10%',
      height: '350px',
      borderRadius: '20px',
      minWidth: '95%',
      position: 'relative',
    },
    textContainer: {
      display: 'flex',
      alignItems: 'baseline', // Align the items at the baseline
      minWidth: '95%',
      padding: '2px',
      justifyContent: 'flex-start', // Align the text content to the left
      textAlign: 'left', // Align the text to the left
    },
    // New style for the new text
    farRightText: {
      fontSize: '22px',
      fontFamily: "'Lato'",
      marginLeft: '30%', // Add left margin to move the text to the right
      fontWeight: 'bold', // Set the font weight to bold
      top: '1%', // Adjust the distance from the top of the cardContainer
      right: '1%', // Adjust the distance from the right of the cardContainer
    },
    farRightText2: {
      fontSize: '15.3px',
      color: 'grey',
      fontFamily: "'Lato'",
      fontWeight: 'bold', // Set the font weight to bold
      top: '1%', // Adjust the distance from the top of the cardContainer
      right: '3%', // Adjust the distance from the right of the cardContainer
    },
    chartContainer: {
      position: 'absolute',
      left: '48%', // Adjust the right position of the chart within the cardContainer
      top: '50%', // Move the chart below the bolded {tickerData.fairValue}
      transform: 'translateY(-50%)', // Adjust the position to center the chart vertically
    },
    intrinsicValueChart: {
      paddingTop: '20px',
      position: 'relative', // Use relative positioning for the chart container
      marginLeft: '300px', // Adjust the left margin to align with the fairValueText
      top: '35px',
    },
    firstLineText: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '10px', // Add some spacing between the first line and the content
      fontFamily: "'Lato', sans-serif",
      marginTop: '-200px',
      display: 'flex', // Add flex display to align the image in the middle
      width: '600px',
    },
    otherText: {
      fontSize: '24px',
      fontFamily: "'Lato', sans-serif",
      position: 'absolute',
      marginTop: '1%',
      left: '1.1%',
      color: '#33404d',
    },
    image: {
      width: '19.5px', // Set the desired width for the image
      height: '19.5px', // Set the desired height for the image
      marginLeft: '5px', // Add some spacing between the word "share" and the image
    },
    secondImage: {
      width: '50px', // Set the desired width for the secondImage
      height: '50px', // Set the desired height for the secondImage
      position: 'absolute', // Position the secondImage absolutely within the cardContainer
      right: '0.9%', // Set the distance from the right edge of the cardContainer
      transform: 'translateY(-50%)', // Adjust the position to center the secondImage vertically
      bottom: '265px',
    },
  };

  return (
    <div style={InfoCardStyles.cardContainer}>
      <div style={InfoCardStyles.textContainer}>
        <p style={InfoCardStyles.firstLineText}>
          {tickerData?.businessSummary}
        </p>
        <p style={InfoCardStyles.farRightText}>
          {tickerData?.symbol.toUpperCase()} DCF Value
          <br />
          <p style={InfoCardStyles.farRightText2}>
            {whichCase}
          </p>
          <br />
          <span style={InfoCardStyles.fairValueText}>${intrinsicValue} USD</span>{' '}
          <img src={tickerData?.imgUrl} style={InfoCardStyles.secondImage} />
        </p>
      </div>
      <div style={InfoCardStyles.chartContainer}>
        <div style={InfoCardStyles.intrinsicValueChart}>
          <IntrinsicValueChart />
        </div>
        <button style={InfoCardStyles.bestCaseButton} onClick={handleBestCaseClick}>Best Case</button>
        <button style={InfoCardStyles.baseCaseButton} onClick={handleBaseCaseClick}>Base Case</button>
      </div>
      <p style={InfoCardStyles.otherText}>
        According to the {whichCase} scenario, the estimated DCF (intrinsic) value of one share of <br /> <span style={{ whiteSpace: 'nowrap' }}> </span>
        <img src={tickerData?.imgUrl} style={InfoCardStyles.image} /> {tickerData?.symbol} is ${intrinsicValue} USD.
      </p>
      <p style={InfoCardStyles.discountRateTitle}>
        DISCOUNT RATE:
      </p>
      <p style={InfoCardStyles.discountRateText}>{(wacc * 100).toFixed(2)} %</p>
      <p style={InfoCardStyles.forecastPeriodTitle}>
        FORECAST PERIOD:
      </p>
      <p style={InfoCardStyles.forecastPeriodText}>
        {years} years
      </p>
      <DiscountRateControl />
    </div>
  );
};

const AvailableValuationModels = () => {
  const tickerData = useSelector((state) => state.tickerData);
  const CalculatorIcon = () => <FaCalculator color="blue" size={28} />;
  const whichCase = useSelector((state) => state.whichCase); // Access whichCase from Redux store
  const AvailableValuationModelsStyles = {
    logo: {
      width: '50px', // Set the desired width for the secondImage
      height: '50px', // Set the desired height for the secondImage
      position: 'absolute', // Position the secondImage absolutely within the cardContainer
      right: '1%', // Set the distance from the right edge of the cardContainer
      transform: 'translateY(-50%)', // Adjust the position to center the secondImage vertically
      bottom: '71.5%', // decrease to lower
    },
    customizeAssumptionsTitle: {
      display: 'flex',
      flexDirection: 'column', // Stack the components vertically
      alignItems: 'center', // Center the components horizontally
    },
    assumptionsBody: {
      flexGrow: 1, // Allow the body to take up all available space
      top: '25%',
      position: 'absolute',
      backgroundColor: '#F1F1F1',
      width: '48%',
      height: '50%',
    },
    horizontalLine: {
      borderTop: '1px solid #ccc', // Use borderTop to create the horizontal line
      width: '98%', // Set the width to span the entire container
      position: 'absolute', // Position the line absolutely within the text container
      bottom: '50%', // Align the line to the bottom of the text container
      transform: 'translateY(50%)', // Adjust the position to center the line vertically
      top: '-7%',
    },
    cardContainer: {
      minWidth: '1500px',
      backgroundColor: 'white',
      padding: '20px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      margin: '10px',
      marginTop: '-400px',
      height: '265px',
      borderRadius: '20px',
      position: 'relative',
    },
    calculatorIcon: {
      position: 'absolute',
      top: '10px',
      left: '10px',
    },
    textNextToIcon: {
      position: 'absolute',
      left: '3.5%', // Adjust the horizontal position as needed
      fontSize: '23px',
      color: 'black',
      bottom: '86.5%',
      fontFamily: 'Lato',
      fontWeight: 'bolder',
    },
    whichScenario: {
      position: 'absolute',
      left: '3.5%', // Adjust the horizontal position as needed
      fontSize: '14px',
      color: 'grey',
      bottom: '81.5%',
      fontFamily: 'Lato',
      fontWeight: 'bolder',
    },
    // New style for the centered text
  };

  const GreyBoxSegment = () => {
    const [revenue, setRevenue] = useState(new Array(6).fill(0));
    const [discountRate, setDiscountRate] = useState(Array.from({ length: revenue.length }, () => tickerData?.WACC));
    const [revenueUnit, setRevenueUnit] = useState('billions'); // Default unit is billions

    const greyBoxStyle = {
      position: 'absolute',
      bottom: '58%',
      backgroundColor: '#F1F1F1', // Light grey background color
      height: '40px', // Height of 40px
      display: 'flex', // Use flex display to arrange year labels
      alignItems: 'center', // Center items vertically
      width: '98%',
      paddingLeft: '35%', // Add left padding to create a gap
    };
    const editRevenue = {
      position: 'absolute',
      left: '-18px', // Adjust the position to align with the text
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    };
    const editOperatingMargin = {
      position: 'absolute',
      left: '-18px', // Adjust the position to align with the text
      display: 'flex',
      top: '20px',
      alignItems: 'center',
      cursor: 'pointer',
    };
    const yearTitleStyle = {
      fontSize: '16px', // Adjust font size as needed
      fontWeight: 'bold', // Add font weight if desired
      color: 'black', // Change the text color
      fontFamily: 'Lato', // Change the font family
      marginLeft: '20px',
      marginRight: '30px', // Increase the right margin to widen the gap
      margin: '0 60px', // Add some margin between year labels
      position: 'relative', // Add position relative

    };

    const dropdownButtonStyle = {
      marginTop: '5px', // Add some margin at the top
      right: '5%',
      marginBottom: '-4%',
      top: '20%',
      position: 'absolute',
    };

    const horizontalLine = {
      borderTop: '1px solid #ccc', // Use borderTop to create the horizontal line
      width: '100%', // Set the width to span the entire container
      position: 'absolute', // Position the line absolutely within the text container
      bottom: '2%', // Align the line to the bottom of the text container
      transform: 'translateY(50%)', // Adjust the position to center the line vertically
      right: '-0.05%',
    };

    const horizontalLineAfterRevenue = {
      borderTop: '1px solid #ccc', // Use borderTop to create the horizontal line
      width: '100%', // Set the width to span the entire container
      position: 'absolute', // Position the line absolutely within the text container
      bottom: '-25px', // Align the line to the bottom of the text container
      transform: 'translateY(50%)', // Adjust the position to center the line vertically
      right: '-0.05%',
    };

    const horizontalLineAfterMargins = {
      borderTop: '1px solid #ccc', // Use borderTop to create the horizontal line
      width: '100%', // Set the width to span the entire container
      position: 'absolute', // Position the line absolutely within the text container
      bottom: '-57px', // Align the line to the bottom of the text container
      transform: 'translateY(50%)', // Adjust the position to center the line vertically
      right: '-0.05%',
    };

    const horizontalLineAfterFcF = {
      borderTop: '1px solid #ccc', // Use borderTop to create the horizontal line
      width: '100%', // Set the width to span the entire container
      position: 'absolute', // Position the line absolutely within the text container
      bottom: '-87px', // Align the line to the bottom of the text container
      transform: 'translateY(50%)', // Adjust the position to center the line vertically
      right: '-0.05%',
    };

    const horizontalLineAfterDiscountRate = {
      borderTop: '1px solid #ccc', // Use borderTop to create the horizontal line
      width: '100%', // Set the width to span the entire container
      position: 'absolute', // Position the line absolutely within the text container
      bottom: '-117px', // Align the line to the bottom of the text container
      transform: 'translateY(50%)', // Adjust the position to center the line vertically
      right: '-0.05%',
    };

    const horizontalLineAfterPresentValue = {
      borderTop: '1px solid #ccc', // Use borderTop to create the horizontal line
      width: '100%', // Set the width to span the entire container
      position: 'absolute', // Position the line absolutely within the text container
      bottom: '-147px', // Align the line to the bottom of the text container
      transform: 'translateY(50%)', // Adjust the position to center the line vertically
      right: '-0.05%',
    };

    const forecastTextStyleFinal = {
      fontSize: '12px', // Adjust font size for the "Forecast" text
      color: 'grey', // Change the text color for the "Forecast" text
      fontFamily: 'Lato', // Change the font family for the "Forecast" text
      marginLeft: '-14px',
    };

    const forecastTextTerminalStyle = {
      fontSize: '12px', // Adjust font size for the "Forecast" text
      color: 'grey', // Change the text color for the "Forecast" text
      fontFamily: 'Lato', // Change the font family for the "Forecast" text
      marginLeft: '0px',
    };

    const revenueTextStyle = {
      fontSize: '16px', // Adjust font size for the "REVENUE" text
      fontWeight: '900', // Add font weight if desired
      color: '#1f2933', // Change the text color for the "REVENUE" text
      fontFamily: 'Lato', // Change the font family for the "REVENUE" text
      top: '43px',
      position: 'absolute',
      left: '0px',
    };

    const fcfMarginTextStyle = {
      fontSize: '16px', // Adjust font size for the "REVENUE" text
      fontWeight: '900', // Add font weight if desired
      color: '#1f2933', // Change the text color for the "REVENUE" text
      fontFamily: 'Lato', // Change the font family for the "REVENUE" text
      position: 'absolute',
      left: '0px',
      top: '77px',
    };

    const fcfTextStyle = {
      fontSize: '16px', // Adjust font size for the "REVENUE" text
      fontWeight: '900', // Add font weight if desired
      color: '#1f2933', // Change the text color for the "REVENUE" text
      fontFamily: 'Lato', // Change the font family for the "REVENUE" text
      position: 'absolute',
      left: '0px',
      top: '108px',
    };

    const dRateTextStyle = {
      fontSize: '16px', // Adjust font size for the "REVENUE" text
      fontWeight: 'bolder', // Add font weight if desired
      color: '#1f2933', // Change the text color for the "REVENUE" text
      fontFamily: 'Lato', // Change the font family for the "REVENUE" text
      position: 'absolute',
      fontStretch: 'expanded', // Expand the characters to make them bolder
      left: '0px',
      top: '137px',
    };

    const presentValueTextStyle = {
      fontSize: '16px', // Adjust font size for the "REVENUE" text
      fontWeight: 'bolder', // Add font weight if desired
      color: '#1f2933', // Change the text color for the "REVENUE" text
      fontFamily: 'Lato', // Change the font family for the "REVENUE" text
      position: 'absolute',
      fontStretch: 'expanded', // Expand the characters to make them bolder
      left: '0px',
      top: '164px',
      textShadow: '0px 0px 0.5px black', // Adjust the blur radius and spread
    };

    const revenueNumberStyle = {
      fontSize: '14px', // Adjust font size for the revenue numbers
      fontWeight: 'normal', // Set font weight to normal
      color: 'black', // Change the text color for the revenue numbers
      fontFamily: 'Lato', // Change the font family for the revenue numbers
      marginTop: '3px', // Add some margin at the top
      position: 'absolute',
      right: '1px',
      top: '100%', // Position the revenue number below the year forecast
      transform: 'translateY(5px)', // Add slight vertical spacing
    };

    const marginNumberStyle = {
      fontSize: '14px', // Adjust font size for the revenue numbers
      fontWeight: 'normal', // Set font weight to normal
      color: 'black', // Change the text color for the revenue numbers
      fontFamily: 'Lato', // Change the font family for the revenue numbers
      marginTop: '3px', // Add some margin at the top
      position: 'absolute',
      right: '-2px',
      top: '68px', // Position the revenue number below the year forecast
      transform: 'translateY(5px)', // Add slight vertical spacing
    };

    const fcfNumberStyle = {
      fontSize: '14px', // Adjust font size for the revenue numbers
      fontWeight: 'normal', // Set font weight to normal
      color: 'black', // Change the text color for the revenue numbers
      fontFamily: 'Lato', // Change the font family for the revenue numbers
      marginTop: '3px', // Add some margin at the top
      position: 'absolute',
      right: '1px',
      top: '98px', // Position the revenue number below the year forecast
      transform: 'translateY(5px)', // Add slight vertical spacing
    };

    const waccNumberStyle = {
      fontSize: '14px', // Adjust font size for the revenue numbers
      fontWeight: 'normal', // Set font weight to normal
      color: 'black', // Change the text color for the revenue numbers
      fontFamily: 'Lato', // Change the font family for the revenue numbers
      marginTop: '3px', // Add some margin at the top
      position: 'absolute',
      right: '-1px',
      top: '125px', // Position the revenue number below the year forecast
      transform: 'translateY(5px)', // Add slight vertical spacing
    };

    const discFcFNumberStyle = {
      fontSize: '14px', // Adjust font size for the revenue numbers
      fontWeight: 'normal', // Set font weight to normal
      color: 'black', // Change the text color for the revenue numbers
      fontFamily: 'Lato', // Change the font family for the revenue numbers
      marginTop: '3px', // Add some margin at the top
      position: 'absolute',
      right: '1px',
      top: '153px', // Position the revenue number below the year forecast
      transform: 'translateY(5px)', // Add slight vertical spacing
      textShadow: '0px 0px .9px black', // Adjust the blur radius and spread
    };

    const financialCurrencyText = {
      fontSize: '13px', // Adjust font size for the "REVENUE" text
      fontWeight: 'bolder', // Add font weight if desired
      fontFamily: 'Lato', // Change the font family for the "REVENUE" text
      marginTop: '5px', // Add some margin at the top
      right: '15%',
      marginBottom: '-4%',
      top: '20%',
      position: 'absolute',
      color: '#7b8793',
    };

    const unitOptions = ['billions', 'millions', 'thousands', 'trillions'];

    const handleChangeUnit = (selectedUnit) => {
      setRevenueUnit(selectedUnit);
    };

    const formatRevenue = (value, unit) => {
      const units = {
        billions: 1e9,
        millions: 1e6,
        thousands: 1e3,
        trillions: 1e12,
      };

      const formattedValue = value / units[unit];
      return formattedValue.toFixed(2);
    };

    const formatMargin = (value) => `${(value * 100).toFixed(2)}%`;

    const labels = revenue.map((_, index) => (
      <div key={index} style={yearTitleStyle}>
        {index === revenue.length - 1 ? 'Terminal' : `Year ${index + 1}`}
        <div style={index === revenue.length - 1 ? forecastTextTerminalStyle : forecastTextStyleFinal}>
          {index === revenue.length - 1 ? 'Forecasted' : 'Forecasted'}
        </div>
        {index < tickerData?.projectedYearlyRevenue.length ? (
          <>
            <div style={revenueNumberStyle}>
              {formatRevenue(tickerData?.projectedYearlyRevenue[index], revenueUnit)}
            </div>
            <div style={marginNumberStyle}>
              {formatMargin(tickerData?.projectedFcfMargins?.[index])}
            </div>
            <div style={fcfNumberStyle}>
              {formatRevenue(tickerData?.projectedFcF?.[index], revenueUnit)}
            </div>
            <div style={fcfNumberStyle}>
              {formatRevenue(tickerData?.projectedFcF?.[index], revenueUnit)}
            </div>
            <div style={waccNumberStyle}>
              {formatMargin(discountRate[index])}
            </div>
            <div style={discFcFNumberStyle}>
              {formatRevenue(tickerData?.projectedFcFDiscounted?.[index], revenueUnit)}
            </div>
          </>
        ) : null}
        {index === revenue.length - 1 ? ( // Print projectedFcFDiscounted[5] for Terminal Forecast
          <div style={discFcFNumberStyle}>
            {formatRevenue(tickerData?.projectedFcFDiscounted?.[5], revenueUnit)}
          </div>
        ) : null}
      </div>
    ));

    return (
      <div>
        <div style={financialCurrencyText}>
          Currency: {tickerData?.financialCurrency}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={greyBoxStyle}>
            {labels}
            <div style={horizontalLine} />
            <div style={revenueTextStyle}>
              REVENUE
            </div>
            <div style={fcfMarginTextStyle}>
              OPERATING MARGIN
            </div>
            <div style={fcfTextStyle}>
              FREE CASH FLOW TO FIRM
            </div>
            <div style={dRateTextStyle}>
              DISCOUNT RATE
            </div>
            <div style={presentValueTextStyle}>
              PRESENT VALUE
            </div>
            <div style={horizontalLineAfterRevenue} />
            <div style={horizontalLineAfterMargins} />
            <div style={horizontalLineAfterFcF} />
            <div style={horizontalLineAfterDiscountRate} />
            <div style={horizontalLineAfterPresentValue} />
          </div>
          <div style={dropdownButtonStyle}>
            <select
              value={revenueUnit}
              onChange={(e) => handleChangeUnit(e.target.value)}
            >
              {unitOptions.map((unit) => (
                <option key={unit} value={unit}>
                  {unit}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={AvailableValuationModelsStyles.cardContainer}>
      <CalculatorIcon style={AvailableValuationModelsStyles.calculatorIcon} />
      <span style={AvailableValuationModelsStyles.textNextToIcon}>
        DCF Model
      </span>
      <img src={tickerData?.imgUrl} style={AvailableValuationModelsStyles.logo} />

      {/* Render the line */}
      <div style={AvailableValuationModelsStyles.horizontalLine} />
      <span style={AvailableValuationModelsStyles.whichScenario}>
        {whichCase} Scenario
      </span>
      <GreyBoxSegment />
      {/* Render the right side component */}
    </div>
  );
};

const IntrinsicValueHistoryChart = () => {
  const tickerData = useSelector((state) => state.tickerData);
  const allDates = tickerData?.allDates;
  const allStockPrices = tickerData?.allStockPrices;
  const allFairValues = tickerData?.allFairValues;
  const IntrinsicValueHistoryChartStyles = {
    logo: {
      width: '50px', // Set the desired width for the secondImage
      height: '50px', // Set the desired height for the secondImage
      position: 'absolute', // Position the secondImage absolutely within the cardContainer
      right: '1%', // Set the distance from the right edge of the cardContainer
      transform: 'translateY(-50%)', // Adjust the position to center the secondImage vertically
      bottom: '82%', // decrease to lower
    },
    customizeAssumptionsTitle: {
      display: 'flex',
      flexDirection: 'column', // Stack the components vertically
      alignItems: 'center', // Center the components horizontally
    },
    assumptionsBody: {
      flexGrow: 1, // Allow the body to take up all available space
      top: '25%',
      position: 'absolute',
      backgroundColor: '#F1F1F1',
      width: '48%',
      height: '50%',
    },
    horizontalLine: {
      borderTop: '1px solid #ccc', // Use borderTop to create the horizontal line
      width: '98%', // Set the width to span the entire container
      position: 'absolute', // Position the line absolutely within the text container
      bottom: '50%', // Align the line to the bottom of the text container
      transform: 'translateY(50%)', // Adjust the position to center the line vertically
      top: '-7%',
    },
    cardContainer: {
      backgroundColor: 'white',
      padding: '20px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      margin: '10px',
      marginTop: '-400px',
      marginLeft: '3%',
      marginRight: '10%',
      height: '450px',
      borderRadius: '20px',
      minWidth: '1600px',
      position: 'relative',
    },
    calculatorIcon: {
      position: 'absolute',
      top: '10px',
      left: '10px',
    },
    textNextToIcon: {
      position: 'absolute',
      left: '3.5%', // Adjust the horizontal position as needed
      fontSize: '23px',
      color: 'black',
      bottom: '86.5%',
      fontFamily: 'Lato',
      fontWeight: 'bolder',
    },
    whichScenario: {
      position: 'absolute',
      left: '3.5%', // Adjust the horizontal position as needed
      fontSize: '14px',
      color: 'grey',
      bottom: '81.5%',
      fontFamily: 'Lato',
      fontWeight: 'bolder',
    },
    // New style for the centered text
  };
  const LineChart = () => {
    const options = {
      style: {
        color: 'white',
      },
      chart: {
        backgroundColor: 'transparent', // Set the background color of the chart to transparent
        height: 400, // Adjust the height as needed
        marginLeft: 50,
        marginRight: 50,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'top',
        itemStyle: {
          color: '#333333', // Legend text color
        },
      },
      xAxis: {
        categories: allDates,
        labels: {
          style: {
            color: '#333333', // X-axis label text color
          },
        },
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          style: {
            color: '#333333', // Y-axis label text color
          },
        },
        gridLineColor: '#e0e0e0', // Y-axis grid line color
      },
      series: [
        {
          name: 'Fair Value',
          data: allFairValues.map((price) => parseFloat(price)),
          color: '#3498db', // Line color for Fair Value
          marker: {
            symbol: 'circle', // Marker shape for Fair Value points
          },
        },
        {
          name: 'Stock Price',
          data: allStockPrices.map((price) => parseFloat(price)),
          color: '#e74c3c', // Line color for Stock Price
          marker: {
            symbol: 'square', // Marker shape for Stock Price points
          },
        },
      ],
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  };

  return (
    <div style={IntrinsicValueHistoryChartStyles.cardContainer}>
      <img src={tickerData?.imgUrl} style={IntrinsicValueHistoryChartStyles.logo} />
      <LineChart />
    </div>
  );
};

const Dashboard = () => {
  const tickerData = useSelector((state) => state.tickerData);
  document.title = `${tickerData?.symbol} DCF Value`;
  const { ticker } = useParams();
  const isDropDownTrue = useSelector((state) => state.isDropdownOpen); // Get the value of isDropdownOpen from the Redux store
  const dispatch = useDispatch(); // Get the dispatch function from React-Redux
  const dashboardStyles = {
    background: `
      radial-gradient(
        circle at 3% 25%,
        rgba(0, 40, 83, 1) 0%,
        rgba(4, 12, 24, 1) 25%
      )`,
    minHeight: '100vh', // Ensure the gradient covers the entire viewport
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://127.0.0.1:8000/api/dcf/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': 'DjnDP6VWF9vtIWX3xzY6RzlV99yrAZfe',
          },
          body: JSON.stringify({ ticker }),
        });
        if (response.ok) {
          const jsonResponse = await response.json();
          // Dispatch the action to update the tickerData state in the store
          dispatch(setTickerData(jsonResponse));
          dispatch(addTicker(jsonResponse.symbol));
        } else {
          console.log('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [ticker, dispatch]);

  useEffect(() => {
    // After dispatching the action, persist the data in the Redux store
    persistor.flush();
  }, [tickerData]);

  if (tickerData?.symbol !== ticker) {
    document.title = 'DCF Loading...';
    // Styles for the loading animation
    const loadingStyles = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    };

    const spinnerStyles = {
      border: '4px solid rgba(0, 0, 0, 0.3)',
      borderTop: '4px solid #3498db',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      animation: 'spin 1s linear infinite', // Apply the spin animation
    };

    // CSS animation keyframes
    const keyframes = `@keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }`;

    // Add the keyframes to the head of the document
    const styleElement = document.createElement('style');
    styleElement.innerHTML = keyframes;
    document.head.appendChild(styleElement);

    return (
      <LoadingAnimationLottie />
    );
  }

  return (
    <div style={dashboardStyles}> {/* Apply the gradient background style */}
      <Navbar />
      {isDropDownTrue && <ToolsDropDown />}
      <Card />
      <DCFTitle />
      <InfoCard />
      <CompetitorTitles />
      <CompetitorCard />
      <ModelSettingsTitle />
      <AvailableValuationModels />
      <IntrinsicValueHistoryTitle />
      <IntrinsicValueHistoryChart />
    </div>
  );
};

const adjustZoom = () => {
  const screenWidth = window.innerWidth;
  // console.log(screenWidth);
  if (screenWidth <= 600) {
    document.body.style.zoom = '50%';
  } else if (screenWidth > 600 && screenWidth <= 1024) {
    document.body.style.zoom = '100%';
  } else {
    document.body.style.zoom = '90%%';
  }
};

const App = () => {
  useEffect(() => {
    adjustZoom(); // Adjust zoom when component mounts
    window.addEventListener('resize', adjustZoom); // Adjust zoom on window resize

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', adjustZoom);
  }, []); // Empty dependency array means this runs once on mount

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/dcf/:ticker/" element={<Dashboard />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/screener" element={<StockScreener />} />
            <Route path="/pricing" element={<PricePage />} />
            <Route path="/trade-finder" element={<TradeFinder />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
};
export default App;
